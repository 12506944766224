import { FieldGroup } from "@banklampung/components";
import React from "react";

const FieldAgreement = ({ handleChange, form }) => {
  return (
    <FieldGroup
      id="agree"
      custom={true}
      propsGroup={{
        variant: "custom-checkbox",
        inline: true,
      }}
      propsLabel={{
        variant: "custom-checkbox",
        order: "end",
      }}
      label="Dengan ini saya menyatakan bahwa data isian adalah benar dan apabila dikemudian hari terbukti bahwa data yang diisikan tidak benar maka saya bersedia bertanggungjawab terhadap ketentuan yang berlaku."
      type="input"
      fieldType="checkbox"
      errorLabel="Isian persetujuan tidak di ceklist"
      isRequired={true}
      validate={`["required"]`}
      invalid={form.invalids.agree}
      errorMessage={form.errorMessages.agree}
      onChange={handleChange}
      value={form.agree}
    />
  );
};

export default FieldAgreement;
