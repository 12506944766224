import React from "react";
import PropTypes from "prop-types";

const TextRight = ({ value }) => {
  return <div className="text-right">{value}</div>;
};

TextRight.propTypes = {
  value: PropTypes.any.isRequired
};

export default TextRight;
