const PARSE_ENDPOINT 	= "areca/v1/identities/parse/:idCard";
const INQUIRY_ENDPOINT 	= "areca/v1/identities/inquiry/:idCard";
const VERIFY_ENDPOINT	= "areca/v1/identities/verify";
const STORE_ENDPOINT	= "areca/v1/identities";

const PROVINCE_ENDPOINT = "areca/v1/regions/province";
const CITY_ENDPOINT 	= "areca/v1/regions/regency";
const DISTRICT_ENDPOINT = "areca/v1/regions/district";
const VILLAGE_ENDPOINT 	= "areca/v1/regions/village";

const REPORT_ENDPOINT 	= "areca/v1/identities/verify-history";

export default {
	PARSE_ENDPOINT,
	INQUIRY_ENDPOINT,
	VERIFY_ENDPOINT,
	STORE_ENDPOINT,
	
	PROVINCE_ENDPOINT,
	CITY_ENDPOINT,
	DISTRICT_ENDPOINT,
	VILLAGE_ENDPOINT,

	REPORT_ENDPOINT
};
