import React from "react";
import PropTypes from "prop-types";
import { LoadLazy, Card } from "@banklampung/components";

const StaticCardFormClean = ({
  formName,
  body,
  footer,
  headerActions,
  labelName,
  propsCard,
}) => {
  return (
    <Card
      footerSlot={footer}
      {...propsCard}
    >
      {labelName && (
        <h5 className="mb-2 mt-3">{ labelName }</h5>
      )}
      <LoadLazy>{body}</LoadLazy>
    </Card>
  );
};

StaticCardFormClean.propTypes = {
  formName: PropTypes.string.isRequired,
  body: PropTypes.any.isRequired,
  headerActions: PropTypes.any,
};

StaticCardFormClean.defaultProps = {
  propsCard: {},
};

export default StaticCardFormClean;
