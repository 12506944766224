import React from "react";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import { FieldMask } from "@banklampung/components";

const FieldMoney = props => {
  return (
    <FieldMask
      data-is-amount="1"
      mask={createNumberMask({
        prefix: "Rp ",
        thousandsSeparatorSymbol: ".",
        decimalSymbol: ","
      })}
      {...props}
    />
  );
};

export default FieldMoney;
