import hostInstance from "./hostInstance";
import jwtDecode from "jwt-decode";
import { AppUtils } from "@banklampung/utils";
import { sessionKey } from "@banklampung/config";

class jwtService extends AppUtils.EventEmitter {
  init() {
    this.setInterceptors();
    this.handleAuthentication();
  }

  setInterceptors = () => {
    hostInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      (err) => {
        return new Promise((resolve, reject) => {
          if (
            err.response.status === 401 &&
            err.config &&
            !err.config.__isRetryRequest
          ) {
            this.emit("onAutoLogout", "Invalid access_token");
            this.setSession(null);
          }
          throw err;
        });
      }
    );
  };

  handleAuthentication = () => {
    let access_token = this.getAccessToken();

    if (this.isAuthTokenValid(access_token)) {
      this.setSession(access_token);
    } else {
      this.setSession(null);
      this.emit("onAutoLogout", "access_token expired");
    }
  };

  setSession = (access_token) => {
    if (access_token) {
      localStorage.setItem(`${sessionKey}`, access_token);
      hostInstance.defaults.headers.common["Authorization"] =
        "Bearer " + access_token;

      this.emit("onAutoLogin", true);
    } else {
      localStorage.removeItem(`${sessionKey}`);
      delete hostInstance.defaults.headers.common["Authorization"];
    }
  };

  logout = () => {
    this.setSession(null);
  };

  isAuthTokenValid = (access_token) => {
    if (!access_token) {
      return false;
    }
    const decoded = jwtDecode(access_token);
    const currentTime = Date.now() / 1000;

    if (decoded.exp < currentTime) {
      console.warn("access token expired");
      return false;
    } else {
      return true;
    }
  };

  getAccessToken = () => {
    return window.localStorage.getItem(`${sessionKey}`);
  };

  decodeJWT = (accessToken) => {
    if(accessToken){
      return jwtDecode(accessToken)
    }else{
      return {};
    }
  }

  getPermission = () => {
    const access_token = this.getAccessToken();
    const { authorities=[], roles=[], identity, jti="" } = this.decodeJWT(access_token);
    return {
      authorities,
      roles, 
      identity,
      jti
    };
  }

  getExp = () => {
    const access_token = this.getAccessToken();
    const { exp="" } = this.decodeJWT(access_token);
    return exp;
  }
}

const instance = new jwtService();

export default instance;
