import { StaticFieldGroup } from "@banklampung/components";
import { cleanValue, notEmpty, GlobalHelper } from "@banklampung/helper";
import { CCol, CRow, CContainer } from "@coreui/react";
import React from "react";

const StaticMultipleColumn = (props) => {
  const { heading, values } = props;

  return (
    <>
      <CContainer>
        <CRow className="align-items-start">
          {values.map((val) => (
            <CCol>
              <StaticFieldGroup
                label={val.label}
                value={val.value}
              />
            </CCol>
          ))}
        </CRow>
      </CContainer>
    </>
  );
};
export default StaticMultipleColumn;
