import * as Actions from "../actions";

const initialState = {
  state: false,
  options: {
    title: "",
    customClass: {
      confirmButton: "btn btn-success mr-1",
      cancelButton: "btn btn-danger ml-1"
    },
    buttonsStyling: false,
    confirmButtonText: '<i class="fa fa-thumbs-up"></i> Baiklah!',
    cancelButtonText: "Tutup"
  }
};

const dialog = function(state = initialState, action) {
  switch (action.type) {
    case Actions.OPEN_DIALOG: {
      return {
        ...state,
        state: true,
        options: {
          ...initialState.options,
          ...action.options
        }
      };
    }
    case Actions.CLOSE_DIALOG: {
      return {
        ...state,
        state: false
      };
    }
    default: {
      return state;
    }
  }
};

export default dialog;
