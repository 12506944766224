import _ from "@banklampung/libs/lodash";

const notEmpty = (value) => !_.isEmpty(value);

const hasAuthority = (authorities, permissions) => {
  if (
    permissions === null ||
    permissions === undefined ||
    permissions.length === 0
  ) {
    return false;
  }

  if (
    authorities === null ||
    authorities === undefined ||
    authorities.length === 0
  ) {
    return false;
  }

  if (
    authorities &&
    Array.isArray(authorities) &&
    permissions &&
    Array.isArray(permissions)
  ) {
    return authorities.some((r) => permissions.indexOf(r) >= 0);
  }

  return false;
};

const isArray = (a) => {
  return Array.isArray(a);
};

const isObject = (o) => {
  return o === Object(o) && !isArray(o) && typeof o !== "function";
};
const isClassComponent = (component) => {
  return (
    typeof component === "function" && !!component.prototype.isReactComponent
  );
};

const isFunctionComponent = (component) => {
  return (
    typeof component === "function" &&
    String(component).includes("return React.createElement")
  );
};

const isReactComponent = (component) => {
  return isClassComponent(component) || isFunctionComponent(component);
};

const cleanValue = (value) => {
  if (isObject(value)) {
    if ("props" in value) {
      return value;
    }

    return value.formatted;
  }

  return value;
};

export { default as CurrencyHelper } from "./CurrencyHelper";
export { default as CustomerDataHelper } from "./CustomerDataHelper";
export { default as DataHelper } from "./DataHelper";
export { default as DateFormatter } from "./DateFormatter";
export { default as DateHelper } from "./DateHelper";
export { default as FieldFormatter } from "./FieldFormatter";
export { default as FormHelper } from "./FormHelper";
export { default as GlobalHelper } from "./GlobalHelper";
export { default as HmacHelper } from "./HmacHelper";
export { default as MaskingHelper } from "./MaskingHelper";
export { default as ModelHelper } from "./ModelHelper";
export { default as RegexHelper } from "./RegexHelper";
export { default as RupiahHelper } from "./RupiahHelper";
export { notEmpty, hasAuthority, isArray, isObject, cleanValue };
