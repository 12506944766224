import _ from "@banklampung/libs/lodash";

class ModelHelper {
  static toBodyRequest = (body) => {
    const newBody = _.clone(body);

    _.each(body, (val, key) => {
      if (typeof val === "object") {
        newBody[key] = val.value;
      } else {
        newBody[key] = val;
      }
    });

    return newBody;
  };

  static toBodyObject = (body) => {
    const newBody = _.clone(body);

    _.each(body, (val, key) => {
      newBody[key] = {
        value: val
      };
    });

    return newBody;
  };
  
}

export default ModelHelper;
