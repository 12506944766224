import { FieldMask, FieldMoney, FieldCurrency, SpanError, FieldAlphaNum } from "@banklampung/components";
import {
  CInput,
  CInputCheckbox,
  CInputFile,
  CInputRadio,
  CSelect,
  CTextarea,
} from "@coreui/react";
import classNames from "classnames";
import React, { memo } from "react";
import Select from "react-select";
import CreatableSelect from 'react-select/creatable';
import AsyncSelect from "react-select/async";

const TypeField = ({
  id,
  label,
  optLabel,
  placeholderText,
  type,
  validate,
  param,
  mask,
  fieldType,
  fieldChild,
  disableDefOpts,
  onChange,
  onChangeSelect,
  options,
  regexInput,
  defVal,
  invalid,
  valid,
  help,
  errorMessage,
  showLabel,
  isRequired,
  propsGroup,
  propsLabel,
  ...props
}) => {
  // const { value: newVals } = props 
  // if(isRequired){
  //   if(newVals){
  //     valid = true;
  //     invalid = false;
  //   }else{
  //     valid = false;
  //     invalid = true;
  //   }
  // }
  
  valid =
    valid === undefined && invalid !== undefined ? invalid === false : valid;

  const className = classNames("form-control", {
    "is-invalid": invalid,
    "is-valid": valid,
  });

  if (type === "mask") {
    return (
      <FieldMask
        id={id}
        name={id}
        mask={mask}
        validate={validate}
        param={param}
        onChange={(event) => onChange(event)}
        className={className}
        defaultValue={defVal}
        {...props}
      />
    );
  } else if (type === "mask-currency"){
    return (
      <FieldCurrency
        id={id}
        name={id}
        validate={validate}
        param={param}
        onChange={(event) => onChange(event)}
        className={className}
        defaultValue={defVal}
        {...props}
      />
    )
  } else if (type === "mask-money") {
    return (
      <FieldMoney
        id={id}
        name={id}
        validate={validate}
        param={param}
        onChange={(event) => onChange(event)}
        className={className}
        defaultValue={defVal}
        {...props}
      />
    );
  } else if (type === "mask-alpha-num") {
    return (
      <FieldAlphaNum
        id={id}
        name={id}
        validate={validate}
        param={param}
        onChange={(event) => onChange(event)}
        className={className}
        defaultValue={defVal}
        {...props}
      />
    );
  } else if (type === "input") {
    const attrs = {
      id: id,
      name: id,
      "data-validate": validate,
      "data-param": param,
      "data-regex-input": regexInput,
      invalid: invalid,
      valid: valid,
      defaultValue: defVal,
      onChange: (event) => onChange(event),
    };

    if (fieldType === "textarea") {
      return <CTextarea {...attrs} {...props} />;
    } else if (fieldType === "file") {
      return <CInputFile {...attrs} {...props} />;
    } else if (fieldType === "checkbox") {
      return <CInputCheckbox {...attrs} {...props} />;
    } else if (fieldType === "radio") {
      return <CInputRadio {...attrs} {...props} />;
    } else if (fieldType === "select" && options) {
      const opts = disableDefOpts
        ? options
        : [{ value: "", label: `${optLabel} ${label}` }, ...options];

      fieldChild = opts.map((opt, index) => {
        return (
          <option key={index} value={opt.value}>
            {opt.label}
          </option>
        );
      });

      return (
        <CSelect {...attrs} {...props}>
          {fieldChild}
        </CSelect>
      );
    } else {
      return <CInput 
        {...attrs} {...props} type={fieldType} 
        style={{ boxShadow: 'rgb(131, 125, 198) 0px 0px 5px' }}
      />;
    }
  } else if (type === "select") {
    return (
      <Select
        id={id}
        name={id}
        className="basic-single"
        placeholder={placeholderText || `${optLabel} ${label}`}
        isSearchable={true}
        onChange={(val) => onChangeSelect(val, id)}
        options={options}
        defaultValue={defVal}
        {...props}
      />
    );
  } else if (type === "async-select") {
    return (
      <AsyncSelect
        id={id}
        name={id}
        cacheOptions
        defaultOptions
        className="basic-single"
        placeholder={`${optLabel} ${label}`}
        onChange={(val) => onChangeSelect(val, id)}
        defaultValue={defVal}
        {...props}
      />
    );
  } else if (type === "multi-select") {
    return (
      <Select
        id={id}
        name={id}
        className="basic-multi-select"
        classNamePrefix="select"
        placeholder={placeholderText || `${optLabel} ${label}`}
        isSearchable={true}
        onChange={(val) => onChangeSelect(val, id)}
        options={options}
        isMulti
        cacheOptions
        defaultOptions
        {...props}
      />
    );
  }
  else if (type === "create-multi-select") {
    return (
      <CreatableSelect
        id={id}
        name={id}
        className="create-multi-select"
        classNamePrefix="select"
        placeholder={placeholderText || `${optLabel} ${label}`}
        isSearchable={true}
        onChange={(val) => onChangeSelect(val, id)}
        options={options}
        isMulti
        cacheOptions
        defaultOptions
        {...props}
      />
    );
  }
  else {
    return <></>;
  }
};

const Field = (props) => {
  const { label, help, errorMessage, invalid, fieldType } = props;

  return (
    <>
      <TypeField {...props} />
      {help && (
        <small className="help-block form-text text-muted">{help}</small>
      )}
      {(errorMessage && invalid == false) && 
        <em className="success valid-feedback d-block">{errorMessage}</em>
      }
      {(errorMessage && invalid == true && fieldType != "checkbox") &&
        errorMessage
          .replaceAll(":label", label)
          .split("\n")
          .map((error, idx) => <SpanError key={idx} error={error} />
      )}
    </>
  );
};

TypeField.defaultProps = {
  optLabel: "Pilih",
  autoComplete: "off",
  disableDefOpts: false,
};

export default memo(Field);
