import { IconButton } from "@banklampung/components";
import React from "react";
import { Link } from "react-router-dom";

const ButtonLink = ({ link, onClick, propsButton, otherProps }) => {
  return (
    <Link to={link} onClick={onClick} {...otherProps}>
      <IconButton {...propsButton} />
    </Link>
  );
};

export default ButtonLink;
