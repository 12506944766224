import { CListGroupItem } from "@coreui/react";
import React, { memo } from "react";

const BasicItemList = ({ key, children, ...other }) => {
  return (
    <CListGroupItem
      key={key}
      style={{
        color: "#3C4B64",
      }}
      {...other}
    >
      {children}
    </CListGroupItem>
  );
};

BasicItemList.defaultProps = {};

export default memo(BasicItemList);
