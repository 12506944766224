import { Authorization } from "@banklampung/components";
import { notEmpty } from "@banklampung/helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CIcon from "@coreui/icons-react";
import { CButton } from "@coreui/react";
import PropTypes from "prop-types";
import React from "react";

const Icon = (props) => {
  const { size, ...pi } = props;
  return <CIcon size={size || "sm"} {...pi} />;
};

const isAuthorized = (permissions) => {
  if (
    permissions === null ||
    permissions === undefined ||
    permissions.length === 0
  ) {
    return true;
  }

  return false;
};

const IconButton = (props) => {
  const { permissions, propsIcon, label, ...pr } = props;
  const { align = "left", icon, ...pi } = propsIcon;

  return (
    <Authorization
      isAuthorized={isAuthorized(permissions)}
      permissions={permissions}
    >
      <CButton {...pr}>
        {align === "left" ? (
          <>
            {notEmpty(icon) ? (
              <FontAwesomeIcon icon={icon} />
            ):(
              <Icon {...pi} />
            )}
            &nbsp; {label}
          </>
        ) : (
          <>
            {label}
            &nbsp;
            <Icon {...pi} />
          </>
        )}
      </CButton>
    </Authorization>
  );
};

IconButton.propTypes = {
  propsIcon: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
};

IconButton.defaultProps = {
  propsIcon: {},
  permissions: [],
};

export default IconButton;
