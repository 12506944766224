import React from "react";
import { CFooter } from "@coreui/react";
import PropTypes from "prop-types";
import { appName, appVersion } from "@banklampung/config";

const Footer = (props) => {
  const { companyName, departementName, websiteLink } = props;

  return (
    <CFooter fixed={false}>
      <div>
        <span>
          {departementName} &copy; {new Date().getFullYear()}
        </span>

        <a
          href={websiteLink}
          target="_blank"
          className="ml-1"
          rel="noopener noreferrer"
        >
          {companyName}
        </a>
      </div>
      <div className="ml-auto">
        <span>{`${appName} Versi ${appVersion}`}</span>
      </div>
    </CFooter>
  );
};

Footer.propTypes = {
  companyName: PropTypes.string,
  departementName: PropTypes.string,
  websiteLink: PropTypes.string,
};

Footer.defaultProps = {
  companyName: "PT Bank Lampung",
  departementName: "Divisi Teknologi Informasi",
  websiteLink: "https://banklampung.co.id",
};
export default React.memo(Footer);
