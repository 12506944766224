import _ from "@banklampung/libs/lodash";
import { Storage } from "./index";
import { BLPG_CONFIG_MENU, BLPG_CONFIG_PRODUCT } from "@app-config/storage";

class AppUtils {
    static convFieldValue = (type, args) => {
        switch (type) {

            case "select": {
                const { opts, value } = args;
                return _.find(opts, { value: value }) || {};
            }

            default: {
                return "";
            }
        }
    };

    static customLogotAction = () => {
        Storage.remove(BLPG_CONFIG_MENU);
        Storage.remove(BLPG_CONFIG_PRODUCT);
    }
}
export default AppUtils;