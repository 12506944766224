const PARAGON_LIST_ENDPOINT = "paragon/v1/merchants";
const PARAGON_FIND_ENDPOINT = "paragon/v1/merchants/find/:id";
const PARAGON_STORE_ENDPOINT = "paragon/v1/merchants/applicant";
const PARAGON_UPDATE_BUSINESS = "paragon/v1/merchants/business";
const PARAGON_UPDATE_CUSTOMER = "paragon/v1/merchants/customer";
const PARAGON_UPDATE_ACCOUNT = "paragon/v1/merchants/account";
const PARAGON_GENERATE_ACQUIRER = "paragon/v1/merchants/acquirer";
const PARAGON_UPDATE_NATIONAL_ACQUIRER = "paragon/v1/merchants/national";
const PARAGON_UPDATE_QR = "paragon/v1/merchants/qr";
const PARAGON_UPDATE_ACQUIRERS = "paragon/v1/merchants/acquirers";
const PARAGON_UPDATE_ATTACHMENT = "paragon/v1/merchants/attachment/:id";
const PARAGON_FIX_MERCHANT = "paragon/v1/merchants/fix-payload";

const PARAGON_MERCHANT_CATEGORY = "paragon/v1/merchant-categories";
const PARAGON_MERCHANT_PICKUP = "paragon/v1/merchants/picked-up/:id";
const PARAGON_MERCHANT_LOCK = "paragon/v1/merchants/lock/:id";
const PARAGON_DOCUMENT_DETAIL = "paragon/v1/merchants/attachment";

const PARAGON_PROPOSAL_LIST = "paragon/v1/proposals";
const PARAGON_PROPOSAL_FIND = "paragon/v1/proposals/find/:id";
const PARAGON_PROPOSAL_FLOW = "paragon/v1/proposals/flows/:id";

const PARAGON_ROLE_LIST = "paragon/v1/roles";
const PARAGON_SUBMIT_ROLE = "paragon/v1/users/roles";
const PARAGON_ROLE_USER = "paragon/v1/users/roles";

const PARAGON_DISCOUNT_RATE_INQUIRY= "paragon/v1/merchant-discount-rate/inquiry";
const PARAGON_DISCOUNT_RATE_UPDATE= "paragon/v1/merchants/discount-rate";
const PARAGON_ACTIVATE_QR_PICKUP = "paragon/v1/merchants/picked-up-publish-qr/:id";
const PARAGON_ACTIVATE_QR_LOCK = "paragon/v1/merchants/lock-publish-qr/:id";
const PARAGON_QR_STATIC_FIND = "paragon/v1/qr/find-static-by-mid";
const PARAGON_QR_FIND_ID = "paragon/v1/qr/find/:id";

const PARAGON_RECIEVE_FTP   = "paragon/v1/merchants/recieve-ftp";
const PARAGON_SEND_FTP      = "paragon/v1/merchants/send-ftp";
const PARAGON_BULK_QR       = "paragon/v1/merchants/generate-bulk-qr/:id";
const PARAGON_MERCHANT_DEVICE = "paragon/v1/merchant-devices";
const PARAGON_DEVICE_ENDPOINT = "paragon/v1/devices";

const PARAGON_MERCHANT_AGGREGATOR = "paragon/v1/merchant-aggregators";

const PARAGON_SIMULATOR_QRIS = "fortune/qr";

export default {
  PARAGON_LIST_ENDPOINT,
  PARAGON_FIND_ENDPOINT,
  PARAGON_STORE_ENDPOINT,
  PARAGON_UPDATE_BUSINESS,
  PARAGON_UPDATE_CUSTOMER,
  PARAGON_UPDATE_ACCOUNT,
  PARAGON_UPDATE_ATTACHMENT,
  PARAGON_GENERATE_ACQUIRER,
  PARAGON_UPDATE_NATIONAL_ACQUIRER,
  PARAGON_UPDATE_QR,
  PARAGON_UPDATE_ACQUIRERS,
  PARAGON_FIX_MERCHANT,
  PARAGON_MERCHANT_CATEGORY,
  PARAGON_MERCHANT_PICKUP,
  PARAGON_MERCHANT_LOCK,
  PARAGON_DOCUMENT_DETAIL,
  PARAGON_PROPOSAL_LIST,
  PARAGON_PROPOSAL_FIND,
  PARAGON_PROPOSAL_FLOW,
  PARAGON_SUBMIT_ROLE,
  PARAGON_ROLE_LIST,
  PARAGON_ROLE_USER,
  PARAGON_DISCOUNT_RATE_INQUIRY,
  PARAGON_DISCOUNT_RATE_UPDATE,
  PARAGON_ACTIVATE_QR_PICKUP,
  PARAGON_ACTIVATE_QR_LOCK,
  PARAGON_QR_STATIC_FIND,
  PARAGON_RECIEVE_FTP,
  PARAGON_SEND_FTP,
  PARAGON_QR_FIND_ID,
  PARAGON_SIMULATOR_QRIS,
  PARAGON_BULK_QR,
  PARAGON_MERCHANT_DEVICE,
  PARAGON_DEVICE_ENDPOINT,
  PARAGON_MERCHANT_AGGREGATOR
};
