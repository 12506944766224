import CIcon from "@coreui/icons-react";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import LaddaButton, { EXPAND_RIGHT } from "react-ladda";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { notEmpty } from "@banklampung/helper";

const LoaderButton = ({
  propsIcon,
  variant,
  label,
  color,
  size,
  block,
  shape,
  pressed,
  active,
  disabled,
  isLoading,
  className,
  ...props
}) => {
  const { size: iSize, icon, ...pi } = propsIcon;
  let btnColor = "btn" + (variant ? "-" + variant : "") + "-" + color;
  let classes = classNames(
    className,
    "btn btn-ladda",
    btnColor,
    size ? "btn-" + size : false,
    block ? "btn-block" : false,
    shape ? "btn-" + shape : false,
    pressed ? "btn-pressed" : false,
    {
      active: active,
      disabled: disabled,
    }
  );
  return (
    <LaddaButton
      {...props}
      type="submit"
      className={classes}
      loading={isLoading}
      data-style={EXPAND_RIGHT}
    >
      {notEmpty(propsIcon) && (
        <>
          {notEmpty(icon) ? (
            <FontAwesomeIcon icon={icon} />
          ):(
            <CIcon size={iSize || "sm"} {...pi} />
          )}
          &nbsp;
        </>
      )}
      {label}
    </LaddaButton>
  );
};

LoaderButton.propTypes = {
  variant: PropTypes.oneOf(["", "ghost", "outline"]),
  propsIcon: PropTypes.object,
  label: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
  block: PropTypes.string,
  shape: PropTypes.string,
  pressed: PropTypes.bool,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

LoaderButton.defaultProps = {
  color: "primary",
  propsIcon: {},
  isLoading: false,
  disabled: false
};

export default LoaderButton;
