import { ActionBtnTable } from "@banklampung/components";
import { HmacHelper } from "@banklampung/helper";
import { useDeleteApi } from "@banklampung/hooks";
import * as GlobalActions from "@banklampung/store/actions";
import React from "react";
import { useDispatch } from "react-redux";
import { history } from "@banklampung/utils";

const CrudAction = ({
  resources,
  resourceName,
  model,
  endpointApi,
  actionReloadTable,
  editPermissions,
  deletePermissions
}) => {
  const dispatch = useDispatch();

  const [, doDelete] = useDeleteApi({
    progressDialog: {
      show: false,
    },
    onSuccess: () => {
      dispatch(
        GlobalActions.openDialog({
          icon: "success",
          title: `Berhasil`,
          text: `Menghapus ${resourceName}`,
          showConfirmButton: true,
          onConfirm: () => {
            dispatch(GlobalActions.closeDialog());

            dispatch({
              type: actionReloadTable,
            });
          },
        })
      );
    },
  });

  const handleClickShow = () => {
    history.push(`${resources}/${model.id}/show`);
  };

  const handleEdit = () => {
    history.push(`${resources}/${model.id}/edit`);
  };

  const handleDelete = () => {
    (async () => {
      const url = `/${endpointApi}/${model.id}`;

      const signHeaders = await HmacHelper.getHeader({
        method: "DELETE",
        path: url,
        param: {},
        body: {},
      });

      doDelete({
        url: url,
        headers: signHeaders,
      });
    })();
  };

  return (
    <ActionBtnTable
      urlShow={`${resources}/${model.id}/show`}
      textDelete={`Anda yakin menghapus ${resourceName}?`}
      onClickDelete={handleDelete}
      onClickEdit={handleEdit}
      onClickShow={handleClickShow}
      editPermissions={editPermissions}
      deletePermissions={deletePermissions}
    />
  );
};

export default CrudAction;
