export const brandFull = ['690 134', `

<svg width="600" height="100" viewBox="0 0 1236 340" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0)">
<path d="M92.6204 37.92L73.9404 42.92L82.7004 75.6L101.38 70.6L92.6204 37.92Z" fill="#F37121"/>
<path d="M361.64 33.4L342.96 38.4L351.72 71.08L370.4 66.08L361.64 33.4Z" fill="#F37121"/>
<path d="M432.29 214.52L292.36 252.06L267.97 161L286.64 156L306 228.38L427.29 195.84L432.29 214.52Z" fill="#F37121"/>
<path d="M314.74 63.54L23.1495 142.1L1.26953 60.02L19.9395 54.99L36.8195 118.32L309.74 44.77L314.74 63.54Z" fill="#1F2B7B"/>
<path d="M419.65 165.6L328.58 190.04L308.56 115.25L23.69 191.66L31.81 222.03L239.64 166.3L244.64 184.98L18.16 245.74L0 177.97L322.25 91.54L342.27 166.33L414.65 146.92L419.65 165.6Z" fill="#EE202E"/>
<path d="M557.04 51.83C566.62 48.03 572.54 38.76 572.54 27.66C572.54 10.79 559.93 0 540.17 0H492.9V106.4H540.17C562.82 106.4 576.19 95.76 576.19 77.67C576.19 65.51 568.74 55.48 557.04 51.83ZM540.47 8.51C554.76 8.51 563.73 15.96 563.73 28.27C563.73 40.58 555.07 47.88 540.47 47.88H501.86V8.51H540.47ZM540.93 97.89H501.87V56.24H540.93C557.19 56.24 567.23 64.14 567.23 76.91C567.22 89.98 557.34 97.89 540.93 97.89Z" fill="#1F2B7B"/>
<path d="M655.42 31.31V46.36C649.64 36.33 638.09 29.94 624.26 29.94C602.22 29.94 586.72 46.05 586.72 68.85C586.72 91.65 602.22 107.76 624.26 107.76C638.09 107.76 649.64 101.38 655.42 91.5V106.4H663.48V31.31H655.42ZM625.02 99.71C607.69 99.71 595.38 87.4 595.38 68.85C595.38 50.3 607.69 38 625.02 38C642.5 38 654.81 50.31 654.81 68.86C654.81 87.41 642.5 99.71 625.02 99.71Z" fill="#1F2B7B"/>
<path d="M720 30.25C707.69 30.25 697.2 35.72 691.27 44.84L691.12 31.31H683.22V106.4H691.88V65.21C691.88 50.01 702.98 38.31 719.09 38.31C734.44 38.31 743.11 47.43 743.11 64V106.41H751.77V63.54C751.76 42.71 739.76 30.25 720 30.25Z" fill="#1F2B7B"/>
<path d="M829.51 106.4L787.56 67.79L824.95 31.31H813.55L779.05 65.36V0H770.39V106.4H779.05V70.98L817.51 106.4H829.51Z" fill="#1F2B7B"/>
<path d="M491.94 252.4H576.94V230.81H515.4V133.4H491.94V252.4Z" fill="#1F2B7B"/>
<path d="M652.39 168.42L650.86 178.45C644.06 170.8 633.69 166.55 621.79 166.55C597.65 166.55 580.99 184.57 580.99 210.41C580.99 236.25 597.65 254.27 621.79 254.27C633.69 254.27 643.89 250.02 650.86 242.2L652.39 252.4H671.94V168.42H652.39V168.42ZM626.38 234.55C612.95 234.55 603.43 224.52 603.43 210.41C603.43 196.3 612.95 186.27 626.38 186.27C639.98 186.27 649.5 196.3 649.5 210.41C649.5 224.52 639.97 234.55 626.38 234.55Z" fill="#1F2B7B"/>
<path d="M790.9 166.72C777.98 166.72 768.12 171.48 761.49 180.66C756.39 171.99 746.87 166.72 735.31 166.72C725.45 166.72 716.95 170.97 711.34 178.28L709.81 168.42H690.26V252.4H712.87V206.16C712.87 194.26 720.18 186.44 731.06 186.44C741.09 186.44 746.7 193.07 746.7 204.97V252.4H769.14V206.16C769.14 194.26 776.45 186.44 787.33 186.44C797.36 186.44 802.97 193.07 802.97 204.97V252.4H825.58V202.42C825.58 180.15 812.32 166.72 790.9 166.72Z" fill="#1F2B7B"/>
<path d="M892.9 166.55C881 166.55 870.63 170.8 863.83 178.45L862.3 168.42H842.75V289.97H865.36V243.73C872.16 250.53 881.85 254.27 892.9 254.27C917.04 254.27 933.7 236.25 933.7 210.41C933.7 184.57 917.04 166.55 892.9 166.55ZM888.31 234.55C874.71 234.55 865.19 224.52 865.19 210.41C865.19 196.3 874.71 186.27 888.31 186.27C901.74 186.27 911.26 196.3 911.26 210.41C911.26 224.52 901.74 234.55 888.31 234.55Z" fill="#1F2B7B"/>
<path d="M1006.26 168.42V212.45C1006.26 225.37 998.27 234.38 986.2 234.38C975.32 234.38 968.35 226.9 968.35 213.98V168.42H945.74V215.34C945.74 239.14 959.51 254.1 980.42 254.1C991.47 254.1 1001.16 250.02 1007.79 242.71L1009.32 252.4H1028.87V168.42H1006.26V168.42Z" fill="#1F2B7B"/>
<path d="M1095.91 166.72C1084.86 166.72 1075 170.8 1068.37 178.11L1066.84 168.42H1047.29V252.4H1069.9V208.37C1069.9 195.45 1078.06 186.44 1090.3 186.44C1101.52 186.44 1108.49 193.92 1108.49 206.84V252.4H1131.1V205.48C1131.1 181.68 1117.33 166.72 1095.91 166.72Z" fill="#1F2B7B"/>
<path d="M1234.16 208.71C1234.16 199.19 1231.44 191.71 1224.98 184.23L1236.37 170.8L1220.05 158.56L1209.68 171.31C1203.73 168.08 1196.42 166.38 1188.6 166.38C1162.08 166.38 1143.21 184.06 1143.21 208.71C1143.21 234.38 1161.06 250.87 1189.11 250.87C1203.05 250.87 1211.21 256.48 1211.21 266.17C1211.21 275.69 1202.2 282.15 1188.77 282.15C1174.32 282.15 1165.65 274.84 1165.99 263.11H1144.06C1142.53 286.06 1160.38 301.7 1188.77 301.7C1216.31 301.7 1234.16 287.93 1234.16 266.68C1234.16 254.44 1227.19 245.09 1214.95 239.99C1227.36 233.53 1234.16 222.48 1234.16 208.71ZM1188.77 231.15C1175 231.15 1165.65 221.97 1165.65 208.71C1165.65 195.11 1174.83 186.1 1188.77 186.1C1202.71 186.1 1211.89 195.11 1211.89 208.71C1211.89 221.97 1202.71 231.15 1188.77 231.15Z" fill="#1F2B7B"/>
<path d="M503.61 304.365C499.605 304.365 496.275 306.12 494.565 308.91V295.5H492V327H494.34L494.385 322.59C496.05 325.515 499.47 327.405 503.61 327.405C510.09 327.405 514.725 322.725 514.725 315.885C514.725 309.045 510.09 304.365 503.61 304.365ZM503.34 325.02C498.21 325.02 494.565 321.375 494.565 315.885C494.565 310.395 498.21 306.75 503.34 306.75C508.515 306.75 512.16 310.395 512.16 315.885C512.16 321.375 508.515 325.02 503.34 325.02Z" fill="#1F2B7B"/>
<path d="M541.988 304.77V309.225C540.278 306.255 536.858 304.365 532.763 304.365C526.238 304.365 521.648 309.135 521.648 315.885C521.648 322.635 526.238 327.405 532.763 327.405C536.858 327.405 540.278 325.515 541.988 322.59V327H544.373V304.77H541.988ZM532.988 325.02C527.858 325.02 524.213 321.375 524.213 315.885C524.213 310.395 527.858 306.75 532.988 306.75C538.163 306.75 541.808 310.395 541.808 315.885C541.808 321.375 538.163 325.02 532.988 325.02Z" fill="#1F2B7B"/>
<path d="M565.155 304.455C561.555 304.455 558.63 306.03 556.83 308.595V295.5H554.265V327H556.83V314.805C556.83 310.305 560.115 306.84 564.885 306.84C569.43 306.84 571.995 309.54 571.995 314.445V327H574.56V314.31C574.56 308.145 571.005 304.455 565.155 304.455Z" fill="#1F2B7B"/>
<path d="M602.978 304.77V309.225C601.268 306.255 597.848 304.365 593.753 304.365C587.228 304.365 582.638 309.135 582.638 315.885C582.638 322.635 587.228 327.405 593.753 327.405C597.848 327.405 601.268 325.515 602.978 322.59V327H605.363V304.77H602.978ZM593.978 325.02C588.848 325.02 585.203 321.375 585.203 315.885C585.203 310.395 588.848 306.75 593.978 306.75C599.153 306.75 602.798 310.395 602.798 315.885C602.798 321.375 599.153 325.02 593.978 325.02Z" fill="#1F2B7B"/>
<path d="M629.971 324.795C634.111 323.535 636.811 319.845 636.811 315.345C636.811 312.42 636.001 310.395 633.751 307.785L636.721 304.275L634.876 302.835L631.996 306.255C630.286 305.13 627.811 304.455 625.291 304.455C618.676 304.455 613.771 309.09 613.771 315.345C613.771 322.005 618.226 326.19 625.336 326.19C630.601 326.19 634.021 328.53 634.021 332.085C634.021 335.685 630.556 338.025 625.291 338.025C620.251 338.025 616.741 335.235 616.786 331.365H614.221C614.131 336.675 618.721 340.41 625.291 340.41C632.086 340.41 636.586 337.08 636.586 332.085C636.586 328.53 634.201 325.92 629.971 324.795ZM625.291 323.805C619.756 323.805 616.336 320.565 616.336 315.345C616.336 310.35 620.026 306.84 625.291 306.84C630.691 306.84 634.246 310.26 634.246 315.345C634.246 320.16 630.376 323.805 625.291 323.805Z" fill="#1F2B7B"/>
<path d="M646.594 299.955C647.809 299.955 648.664 299.1 648.664 297.93C648.664 296.805 647.809 295.95 646.594 295.95C645.424 295.95 644.569 296.805 644.569 297.93C644.569 299.1 645.424 299.955 646.594 299.955ZM645.334 327H647.899V304.77H645.334V327Z" fill="#1F2B7B"/>
<path d="M676.601 304.77V309.225C674.891 306.255 671.471 304.365 667.376 304.365C660.851 304.365 656.261 309.135 656.261 315.885C656.261 322.635 660.851 327.405 667.376 327.405C671.471 327.405 674.891 325.515 676.601 322.59V327H678.986V304.77H676.601ZM667.601 325.02C662.471 325.02 658.826 321.375 658.826 315.885C658.826 310.395 662.471 306.75 667.601 306.75C672.776 306.75 676.421 310.395 676.421 315.885C676.421 321.375 672.776 325.02 667.601 325.02Z" fill="#1F2B7B"/>
<path d="M729.185 304.455C725.63 304.455 722.615 306.3 720.995 309.54C719.78 306.3 716.945 304.455 713.12 304.455C710.015 304.455 707.405 305.895 705.785 308.505L705.74 304.77H703.4V327H705.965V314.535C705.965 310.08 708.935 306.84 713.03 306.84C717.17 306.84 719.465 309.45 719.465 314.22V327H722.03V314.535C722.03 310.08 725 306.84 729.14 306.84C733.28 306.84 735.575 309.45 735.575 314.22V327H738.14V314.04C738.14 308.055 734.765 304.455 729.185 304.455Z" fill="#1F2B7B"/>
<path d="M757.392 304.365C750.732 304.365 746.187 309.135 746.187 315.885C746.187 322.725 750.867 327.495 757.572 327.495C761.487 327.495 765.042 325.92 767.157 323.31L765.402 321.78C763.872 323.76 760.722 325.065 757.617 325.065C752.622 325.065 749.202 321.96 748.797 316.875H768.597C769.227 309.36 764.727 304.365 757.392 304.365ZM757.437 306.705C762.567 306.705 766.032 309.855 766.122 314.625H748.797C749.292 309.81 752.532 306.705 757.437 306.705Z" fill="#1F2B7B"/>
<path d="M776.695 327H779.26V295.5H776.695V327Z" fill="#1F2B7B"/>
<path d="M807.962 304.77V309.225C806.252 306.255 802.832 304.365 798.737 304.365C792.212 304.365 787.622 309.135 787.622 315.885C787.622 322.635 792.212 327.405 798.737 327.405C802.832 327.405 806.252 325.515 807.962 322.59V327H810.347V304.77H807.962ZM798.962 325.02C793.832 325.02 790.187 321.375 790.187 315.885C790.187 310.395 793.832 306.75 798.962 306.75C804.137 306.75 807.782 310.395 807.782 315.885C807.782 321.375 804.137 325.02 798.962 325.02Z" fill="#1F2B7B"/>
<path d="M836.98 304.77L831.67 317.1L828.79 323.895L826 317.1L820.96 304.77H818.17L827.35 326.865L822.625 337.71H825.46L839.77 304.77H836.98Z" fill="#1F2B7B"/>
<path d="M865.35 304.77V309.225C863.64 306.255 860.22 304.365 856.125 304.365C849.6 304.365 845.01 309.135 845.01 315.885C845.01 322.635 849.6 327.405 856.125 327.405C860.22 327.405 863.64 325.515 865.35 322.59V327H867.735V304.77H865.35ZM856.35 325.02C851.22 325.02 847.575 321.375 847.575 315.885C847.575 310.395 851.22 306.75 856.35 306.75C861.525 306.75 865.17 310.395 865.17 315.885C865.17 321.375 861.525 325.02 856.35 325.02Z" fill="#1F2B7B"/>
<path d="M888.517 304.455C884.872 304.455 881.767 306.075 880.012 308.775L879.967 304.77H877.627V327H880.192V314.805C880.192 310.305 883.477 306.84 888.247 306.84C892.792 306.84 895.357 309.54 895.357 314.445V327H897.922V314.31C897.922 308.145 894.367 304.455 888.517 304.455Z" fill="#1F2B7B"/>
<path d="M908.745 299.955C909.96 299.955 910.815 299.1 910.815 297.93C910.815 296.805 909.96 295.95 908.745 295.95C907.575 295.95 906.72 296.805 906.72 297.93C906.72 299.1 907.575 299.955 908.745 299.955ZM907.485 327H910.05V304.77H907.485V327Z" fill="#1F2B7B"/>
<path d="M953.094 295.5V308.91C951.384 306.12 948.054 304.365 944.049 304.365C937.569 304.365 932.934 309.045 932.934 315.885C932.934 322.725 937.569 327.405 944.049 327.405C948.189 327.405 951.609 325.56 953.274 322.635L953.319 327H955.659V295.5H953.094ZM944.319 325.02C939.189 325.02 935.499 321.375 935.499 315.885C935.499 310.395 939.189 306.75 944.319 306.75C949.449 306.75 953.094 310.395 953.094 315.885C953.094 321.375 949.449 325.02 944.319 325.02Z" fill="#1F2B7B"/>
<path d="M984.406 304.77V309.225C982.696 306.255 979.276 304.365 975.181 304.365C968.656 304.365 964.066 309.135 964.066 315.885C964.066 322.635 968.656 327.405 975.181 327.405C979.276 327.405 982.696 325.515 984.406 322.59V327H986.791V304.77H984.406ZM975.406 325.02C970.276 325.02 966.631 321.375 966.631 315.885C966.631 310.395 970.276 306.75 975.406 306.75C980.581 306.75 984.226 310.395 984.226 315.885C984.226 321.375 980.581 325.02 975.406 325.02Z" fill="#1F2B7B"/>
<path d="M1006.49 304.5C1003.3 304.5 1000.64 306.3 999.069 309.405L999.024 304.77H996.684V327H999.249V315.705C999.249 310.71 1002.26 306.975 1006.4 306.975C1007.75 306.975 1009.01 307.335 1010.18 308.055L1011.31 305.85C1010.05 304.995 1008.29 304.5 1006.49 304.5Z" fill="#1F2B7B"/>
<path d="M1019.06 299.955C1020.27 299.955 1021.13 299.1 1021.13 297.93C1021.13 296.805 1020.27 295.95 1019.06 295.95C1017.89 295.95 1017.03 296.805 1017.03 297.93C1017.03 299.1 1017.89 299.955 1019.06 299.955ZM1017.8 327H1020.36V304.77H1017.8V327Z" fill="#1F2B7B"/>
<path d="M1055.62 304.455C1052.02 304.455 1049.1 306.03 1047.3 308.595V295.5H1044.73V327H1047.3V314.805C1047.3 310.305 1050.58 306.84 1055.35 306.84C1059.9 306.84 1062.46 309.54 1062.46 314.445V327H1065.03V314.31C1065.03 308.145 1061.47 304.455 1055.62 304.455Z" fill="#1F2B7B"/>
<path d="M1093.44 304.77V309.225C1091.73 306.255 1088.31 304.365 1084.22 304.365C1077.69 304.365 1073.1 309.135 1073.1 315.885C1073.1 322.635 1077.69 327.405 1084.22 327.405C1088.31 327.405 1091.73 325.515 1093.44 322.59V327H1095.83V304.77H1093.44ZM1084.44 325.02C1079.31 325.02 1075.67 321.375 1075.67 315.885C1075.67 310.395 1079.31 306.75 1084.44 306.75C1089.62 306.75 1093.26 310.395 1093.26 315.885C1093.26 321.375 1089.62 325.02 1084.44 325.02Z" fill="#1F2B7B"/>
<path d="M1118.41 324.075C1117.33 324.615 1115.89 325.065 1114.81 325.065C1111.57 325.065 1110.85 323.04 1110.85 320.115V307.065H1118.28V304.77H1110.85V298.11L1108.33 298.38V304.77H1103.65V307.065H1108.33V320.115C1108.33 324.75 1110 327.405 1114.68 327.405C1116.21 327.405 1117.78 326.955 1119.27 326.235L1118.41 324.075Z" fill="#1F2B7B"/>
<path d="M1127.65 299.955C1128.87 299.955 1129.72 299.1 1129.72 297.93C1129.72 296.805 1128.87 295.95 1127.65 295.95C1126.48 295.95 1125.63 296.805 1125.63 297.93C1125.63 299.1 1126.48 299.955 1127.65 299.955ZM1126.39 327H1128.96V304.77H1126.39V327Z" fill="#1F2B7B"/>
</g>
</svg>

<path d="M495.88 15.74h1.02v102.4h-1.02z" fill-rule="evenodd" fill="#2f318b"/>
<path d="M574.55 72.9v.52l.36-.27-.36-.25zm6.57-12.4a16 16 0 0 1-1.95 7.67 9.48 9.48 0 0 1-4.57-7l.02-.67a8.97 8.97 0 0 0-.07-1.17 9.5 9.5 0 0 0-4.57-7 9.57 9.57 0 0 0-4.86-1.33 9.5 9.5 0 1 0 0 19c1 0 1.96-.15 2.86-.44v6.7c-.93.16-1.88.25-2.86.25a16 16 0 0 1-16-15.56h-.05V33.28a3.3 3.3 0 0 1 3.3-3.28c.9 0 1.72.37 2.32.96.58.6.96 1.42.96 2.32v14.3a15.99 15.99 0 0 1 9.47-3.09 15.95 15.95 0 0 1 9.11 2.84c1.8 1.26 3.35 2.88 4.5 4.76 1.5 2.44 2.38 5.32 2.38 8.4z" fill="#3dc0ee"/>
<path d="M619.02 33.28v27.66h-.05c-.23 8.64-7.3 15.56-16 15.56a15.99 15.99 0 0 1-9.82-3.36c1.8-1.26 3.34-2.9 4.5-4.77a9.45 9.45 0 0 0 5.32 1.63 9.5 9.5 0 1 0 0-19c-1.8 0-3.47.5-4.9 1.35a9.55 9.55 0 0 0-4.57 7.37c-.02.26-.03.52-.03.78a2.62 2.62 0 0 0 .01.28 9.55 9.55 0 0 1-4.57 7.37c-1.24-2.27-1.94-4.88-1.94-7.65a15.91 15.91 0 0 1 2.36-8.37c1.16-1.88 2.7-3.5 4.5-4.77 2.6-1.8 5.74-2.86 9.14-2.86a15.99 15.99 0 0 1 9.47 3.09v-14.3c0-.9.38-1.72.96-2.32.6-.6 1.42-.96 2.32-.96 1.8 0 3.3 1.47 3.3 3.28z" fill="#243165"/>
<path d="M574.23 47.34a15.99 15.99 0 0 1 19.61.02 16.38 16.38 0 0 0-4.5 4.77 9.45 9.45 0 0 0-5.32-1.63c-1.95 0-3.77.6-5.28 1.6a16.22 16.22 0 0 0-4.5-4.76zM600.02 60a15.91 15.91 0 0 1-2.36 8.37 16.38 16.38 0 0 1-4.5 4.77c-2.6 1.8-5.74 2.86-9.14 2.86a15.95 15.95 0 0 1-9.11-2.84l-.36-.25v14.3c0 .9-.38 1.72-.96 2.32-.6.6-1.42.96-2.32.96-1.8 0-3.3-1.47-3.3-3.28V59.56h.05c.07-2.62.76-5.07 1.95-7.23 2.46 1.47 4.2 4.02 4.57 7l-.03.67c0 .4.02.8.08 1.17.36 2.98 2.1 5.54 4.57 7a9.41 9.41 0 0 0 4.85 1.33 9.55 9.55 0 0 0 9.47-8.72l.03-.78v-.28a9.55 9.55 0 0 1 4.57-7.37C599.3 54.62 600 57.23 600 60z" fill="#0974b9"/><defs >
<path id="B" d="M531.7 101.7c0 .05-.02.1-.06.12a.38.38 0 0 1-.18.06c-.08.01-.2.02-.36.02-.17 0-.3-.01-.37-.02s-.13-.03-.16-.06-.05-.07-.05-.12v-.4a2.5 2.5 0 0 1-.7.52c-.26.12-.56.18-.88.18a2.74 2.74 0 0 1-.73-.1c-.22-.07-.4-.17-.58-.3-.16-.14-.3-.3-.38-.5-.1-.2-.13-.44-.13-.7a1.67 1.67 0 0 1 .17-.76c.1-.22.28-.4.5-.53s.5-.24.84-.3.73-.1 1.17-.1h.5v-.3a1.64 1.64 0 0 0-.05-.41.55.55 0 0 0-.16-.29c-.07-.08-.17-.14-.3-.17-.12-.04-.27-.06-.45-.06-.24 0-.45.03-.63.08a3.97 3.97 0 0 0-.49.17l-.36.17c-.1.05-.17.08-.23.08-.04 0-.08-.01-.1-.04s-.06-.06-.08-.1-.04-.1-.05-.18a1.64 1.64 0 0 1-.02-.23.91.91 0 0 1 .03-.27.34.34 0 0 1 .11-.18 1.23 1.23 0 0 1 .27-.18c.13-.07.28-.13.46-.18a4.75 4.75 0 0 1 .57-.14c.2-.04.42-.05.64-.05.4 0 .73.04 1 .12.28.08.5.2.7.35a1.34 1.34 0 0 1 .39.61c.08.25.12.55.12.9v3.35zm-1.4-2.14h-.54a3.13 3.13 0 0 0-.58.05c-.16.03-.3.08-.4.15a.71.71 0 0 0-.22.24c-.05.1-.07.2-.07.33 0 .2.07.37.2.5s.32.18.55.18c.2 0 .38-.05.55-.15s.33-.25.5-.44v-.85z"/>
<path id="C" d="M541.06 101.68c0 .04-.01.07-.03.1s-.06.05-.1.07-.12.03-.2.04-.2.01-.34.01-.26 0-.35-.01a1.09 1.09 0 0 1-.22-.04c-.05-.02-.1-.04-.1-.07s-.03-.06-.03-.1v-2.96a2.16 2.16 0 0 0-.05-.46c-.03-.14-.08-.26-.15-.36s-.15-.17-.25-.23c-.1-.05-.22-.08-.36-.08-.17 0-.34.07-.5.2s-.36.32-.56.57v3.32c0 .04-.01.07-.03.1s-.06.05-.1.07-.13.03-.22.04-.2.01-.34.01-.25 0-.34-.01a1.09 1.09 0 0 1-.22-.04c-.05-.02-.1-.04-.1-.07s-.03-.06-.03-.1v-2.96a2.16 2.16 0 0 0-.05-.46c-.03-.14-.08-.26-.14-.36s-.15-.17-.25-.23c-.1-.05-.22-.08-.35-.08-.17 0-.34.07-.52.2a2.74 2.74 0 0 0-.55.57v3.32c0 .04-.01.07-.03.1s-.06.05-.1.07-.13.03-.22.04-.2.01-.34.01-.25 0-.34-.01a1.09 1.09 0 0 1-.22-.04c-.05-.02-.1-.04-.1-.07s-.03-.06-.03-.1v-5c0-.04.01-.07.03-.1s.05-.05.1-.07a.78.78 0 0 1 .19-.04c.08-.01.17-.01.3-.01l.3.01c.08.01.14.02.18.04s.07.04.1.07.03.06.03.1v.58c.28-.3.55-.52.83-.67s.56-.22.86-.22c.2 0 .4.02.55.06s.3.1.44.2c.13.08.24.18.33.3a2.05 2.05 0 0 1 .24.38c.15-.16.3-.3.44-.4a2.1 2.1 0 0 1 .43-.28c.14-.07.28-.13.42-.16s.28-.05.43-.05c.33 0 .6.06.84.17s.4.26.55.46a1.86 1.86 0 0 1 .3.67c.06.26.1.53.1.82v3.17z"/>
<path id="D" d="M591.7 101.68c0 .04-.01.07-.03.1s-.06.05-.1.07-.12.03-.22.04-.2.01-.34.01-.25 0-.34-.01a1.09 1.09 0 0 1-.22-.04c-.05-.02-.1-.04-.1-.07s-.03-.06-.03-.1v-2.85c0-.24-.02-.43-.05-.57a1.5 1.5 0 0 0-.15-.36.72.72 0 0 0-.26-.23c-.1-.05-.23-.08-.37-.08-.18 0-.36.07-.54.2a3.05 3.05 0 0 0-.57.57v3.32c0 .04-.01.07-.03.1s-.06.05-.1.07-.13.03-.22.04-.2.01-.34.01-.25 0-.34-.01a1.09 1.09 0 0 1-.22-.04c-.05-.02-.1-.04-.1-.07s-.03-.06-.03-.1v-5c0-.04.01-.07.03-.1s.05-.05.1-.07a.78.78 0 0 1 .19-.04c.08-.01.17-.01.3-.01l.3.01c.08.01.14.02.18.04s.07.04.1.07.03.06.03.1v.58a3.29 3.29 0 0 1 .84-.67 1.89 1.89 0 0 1 .89-.22c.34 0 .63.06.86.17a1.51 1.51 0 0 1 .57.46 1.79 1.79 0 0 1 .31.67c.06.26.1.57.1.93v3.08z"/>
<path id="E" d="M614.95 95c0 .28-.06.48-.17.6-.12.1-.33.16-.64.16-.32 0-.53-.05-.64-.16-.1-.1-.17-.3-.17-.57s.06-.48.17-.6.33-.17.65-.17c.3 0 .53.05.64.16.1.12.16.3.16.58zm-.1 6.68c0 .04-.01.07-.03.1s-.06.05-.1.07-.13.03-.22.04-.2.01-.34.01-.25 0-.34-.01a1.09 1.09 0 0 1-.22-.04c-.05-.02-.1-.04-.1-.07s-.03-.06-.03-.1V96.7c0-.04.01-.07.03-.1s.06-.05.1-.07a.9.9 0 0 1 .22-.05 2.96 2.96 0 0 1 .34-.02 2.96 2.96 0 0 1 .34.02 1.03 1.03 0 0 1 .22.05c.05.02.1.04.1.07s.03.06.03.1v4.98z"/></defs>`]
