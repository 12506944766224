import CryptoJS from "crypto-js";

const KEY_DEVICE_ID     = "BLPG_DeviceID";
const KEY_DEVICE_OS     = "BLPG_DeviceOS";
const KEY_DEVICE_MODEL  = "BLPG_DeviceModel";
const KEY_DEVICE_IP     = "BLPG_DeviceIP";

const  uuidv4 = () => { 
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
    .replace(/[xy]/g, function (c) { 
        const r = Math.random() * 16 | 0,  
            v = c == 'x' ? r : (r & 0x3 | 0x8); 
        return v.toString(16); 
    }); 
}

const getLocalStorage = (key, func) => {
    const value = localStorage.getItem(key);
    if (!value || value == null || value == undefined) {
        const newVal = func;
        localStorage.setItem(key, newVal);
        return newVal;
    }else{
        return value;
    }
}

const getDeviceHeader = () => {
    return {
        operatingSystem: getLocalStorage( KEY_DEVICE_OS, detectOS()),
        deviceId:  getLocalStorage( KEY_DEVICE_ID, deviceID()),
        deviceModel: getLocalStorage( KEY_DEVICE_MODEL, userAgent()),
        clientIp: getLocalStorage( KEY_DEVICE_IP, clinetIP())
    }
}

const detectOS = () => {
    const platform = navigator.platform;
    if (platform.indexOf('Win') !== -1) return 'Windows';
    if (platform.indexOf('Mac') !== -1) return 'Mac OS';
    if (platform.indexOf('Linux') !== -1) return 'Linux';
    if (platform.indexOf('iPhone') !== -1) return 'iOS';
    if (platform.indexOf('Android') !== -1) return 'Android';
    if (platform.indexOf('iPad') !== -1) return 'iPad';
    return 'Unknown';
}

const userAgent = () => {
    return navigator.appVersion ?? navigator.userAgent;
}

const deviceID = () => {
    const randomString = uuidv4();
    const newDeviceId = CryptoJS.MD5(randomString).toString();
    return newDeviceId;
}

const clinetIP = () => {
    const os = require("os");
    const interfaces = Object.values(os.networkInterfaces())
    for (let iface of interfaces) {
        for (let alias of iface) {
            if (alias.family === "IPv4"
            && alias.address !== "127.0.0.1"
            && !alias.internal) {
                return alias.address
            }
        }
    }
    return "0.0.0.0"
}  

export { getDeviceHeader, getLocalStorage, uuidv4 };
