import _ from "@banklampung/libs/lodash";
import { FormValidator } from "@banklampung/utils";

class FormHelper {
  static bulkValidateById = (formKeys, id, addInputs = []) => {
    const formField = document.getElementById(id);

    if (formField) {
      const inputs = [...formField.elements]
        .filter((i) => formKeys.includes(i.name))
        .map((i) => {
          if (addInputs.includes(i.name)) {
            i.dataset.validate = `["required"]`;
          }
          return i;
        });
        
      return FormValidator.bulkValidate(inputs);
    }

    return {
      errors: {},
      hasError: false,
      invalids: {},
      errorMessages: {},
    };
  };

  static bulkValidateByModelId = (form, id) => {
    const { errors, hasError, invalids, errorMessages, ...inputNames } = form;

    return this.bulkValidateById(Object.keys(inputNames), id);
  };

  static getValuesFormRepeat = (list) => {
    return list
      .map((value) => {
        return value.attribute.map((attr) => {
          let cval = attr.value;

          if (attr.fieldType === "checkbox") {
            cval = attr.checked;
          }

          return {
            [attr.id]: cval,
          };
        });
      })
      .map((arr) =>
        _.reduce(
          arr,
          (memo, current) => {
            return _.assign(memo, current);
          },
          {}
        )
      );
  };

  static bulkValidateFormRepeat = (list, attributes, formId) => {
    return list.map((model, index) => {
      const result = this.bulkValidateById(
        attributes.map((val) => `${index}-${val}`),
        `${formId}-${index}`
      );
      return {
        ...model,
        ...result,
        hasValidated: true,
      };
    });
  };

  static resultValidateFormRepeat = (list) => {
    const hasError = list.filter(({ hasError }) => hasError).length > 0;
    const hasValidated =
      list.filter(({ hasValidated }) => hasValidated).length === list.length;

    return {
      hasError,
      hasValidated,
    };
  };

  static getListRepeatValid = () => ({
    hasError: false,
    hasValidated: true,
    validated: Date.now(),
  });

  static getAttrRepeatValid = () => ({
    hasError: false,
    hasValidated: true,
  });

  static getAttrRepeatHideDelete = () => ({
    ...this.getAttrRepeatValid(),
    showDelete: false,
  });

  static mergeAttrById = (attrs, arrays) =>
    _.map(attrs, (attr) => _.merge(attr, _.find(arrays, { id: attr.id })));

    static leftRightCol = (arr, n) => {
      let chunkLength = Math.max(arr.length / n, 1);
      let chunks = [];
      for (let i = 0; i < n; i++) {
        if (chunkLength * (i + 1) <= arr.length)
          chunks.push(arr.slice(chunkLength * i, chunkLength * (i + 1)));
      }
      return chunks;
    };
    
  static itemRepeatRead = (values) =>
    values
      .map((obj) =>
        obj.attribute.map((atr) => {
          const { label, value } = atr;
          return {
            label: label,
            value: typeof value === "object" ? value.formatted : value,
          };
        })
      )
      .map((val) => this.leftRightCol(val, 2));
}

export default FormHelper;
