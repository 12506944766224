import { ColTwo, StaticFieldGroup, Card } from "@banklampung/components";
import React from "react";
import { cleanValue } from "@banklampung/helper";

const Repeat = (props) => {
  const { key, lefts, rights } = props;

  return (
    <ColTwo
      key={key}
      propsColA={{ md: 6 }}
      propsColB={{ md: 6 }}
      childA={lefts.map((obj, key) => (
        <div key={`left-${key}`}>
          <StaticFieldGroup
            label={obj.label}
            value={cleanValue(obj.value)}
            help={obj.help}
          />
        </div>
      ))}
      childB={rights.map((obj, key) => (
        <div key={`right-${key}`}>
          <StaticFieldGroup
            label={obj.label}
            value={cleanValue(obj.value)}
            help={obj.help}
          />
        </div>
      ))}
    />
  );
};

const ItemRepeat = (props) => {
  const { values, card } = props;

  return values.map(([lefts, rights], idx) => (
    <div key={idx}>
      {card ? (
        <Card >
          <Repeat lefts={lefts} rights={rights} />
        </Card>
      ) : (
        <Repeat lefts={lefts} rights={rights} />
      )}
    </div>
  ));
};

Repeat.defaultProps = {
  lefts: [],
  rights: [],
};

ItemRepeat.defaultProps = {
  card: true,
};

export default ItemRepeat;
