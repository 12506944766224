export const avatar = ['160 160', `
  <title>coreui logo</title>
  <g>
	<circle style="fill:#2E5871;" cx="72.387" cy="72.386" r="72.386"/>
	<g>
		<defs>
			<circle id="SVGID_1_" cx="72.387" cy="72.386" r="72.386"/>
		</defs>
		<clipPath id="SVGID_2_">
			<use xlink:href="#SVGID_1_"  style="overflow:visible;"/>
		</clipPath>
		<g style="clip-path:url(#SVGID_2_);">
			<g>
				<path style="fill:#F1C9A5;" d="M107.053,116.94c-4.666-8.833-34.666-14.376-34.666-14.376s-30,5.543-34.666,14.376
					c-3.449,12.258-6.334,27.833-6.334,27.833h41h41C113.387,144.773,111.438,128.073,107.053,116.94z"/>
				<path style="fill:#E4B692;" d="M72.387,102.564c0,0,30,5.543,34.666,14.376c4.386,11.133,6.334,27.833,6.334,27.833h-41V102.564
					z"/>
				<rect x="64.22" y="84.606" style="fill:#F1C9A5;" width="16.334" height="27.336"/>
				<rect x="72.387" y="84.606" style="fill:#E4B692;" width="8.167" height="27.336"/>
				<path style="opacity:0.1;fill:#DDAC8C;" d="M64.22,97.273c1.469,4.217,7.397,6.634,11.751,6.634
					c1.575,0,3.107-0.264,4.583-0.747V84.606H64.22V97.273z"/>
				<path style="fill:#FFFFFF;" d="M107.053,116.94c-2.726-5.158-14.082-9.191-23.065-11.656c-0.351,6.11-5.402,10.96-11.601,10.96
					c-6.198,0-11.249-4.85-11.601-10.96c-8.983,2.465-20.34,6.498-23.066,11.656c-3.449,12.258-6.334,27.833-6.334,27.833h41h41
					C113.387,144.773,111.438,128.073,107.053,116.94z"/>
				<path style="fill:#F1C9A5;" d="M93.387,67.357c0-17.074-9.402-26.783-21-26.783c-11.598,0-21,9.709-21,26.783
					c0,7.153,2.188,22.991,5.924,26.219c2.565,2.279,10.938,6.183,15.033,6.183h0.001c0.014,0,0.028-0.002,0.043-0.002
					c0.014,0,0.028,0.002,0.041,0.002h0.002c4.096,0,12.469-3.903,15.033-6.183C91.348,89.474,93.387,73.923,93.387,67.357z"/>
				<path style="fill:#E4B692;" d="M72.388,99.757c0.014,0,0.028,0.002,0.041,0.002h0.002c4.096,0,12.469-3.903,15.033-6.183
					c3.884-4.103,5.923-19.653,5.923-26.219c0-17.074-9.402-26.783-21-26.783L72.388,99.757L72.388,99.757z"/>
				<path style="fill:#E4B692;" d="M90.19,79.197c-3.807-0.398-6.377-4.5-5.732-9.156c0.637-4.66,4.242-8.12,8.051-7.724
					c3.805,0.396,6.371,4.496,5.729,9.156C97.599,76.134,93.997,79.591,90.19,79.197z"/>
				<path style="fill:#F1C9A5;" d="M46.685,71.474c-0.643-4.66,1.924-8.76,5.727-9.156c3.81-0.397,7.416,3.063,8.055,7.724
					c0.643,4.656-1.93,8.758-5.734,9.156C50.925,79.591,47.323,76.134,46.685,71.474z"/>
				<path style="fill:#FB6020;" d="M37.721,116.94c-3.449,12.258-6.334,27.833-6.334,27.833h29.777
					c-5.054-22.305-5.249-38.023-5.249-38.053C48.095,109.196,39.97,112.684,37.721,116.94z"/>
				<path style="fill:#FB6020;" d="M107.053,116.94c4.67,11.533,6.334,27.833,6.334,27.833H83.609
					c5.054-22.305,5.249-38.023,5.249-38.053C96.679,109.196,104.804,112.684,107.053,116.94z"/>
			</g>
			<path style="fill:#915F2C;" d="M52.672,78.725c0,0,3.067,10.331,6.388,12.085c1.533,0,2.683,0,2.683,0l3.32-4.705l7.281-2.029
				v15.684h-9.453l-9.452-8.488L52.672,78.725z"/>
			<path style="fill:#915F2C;" d="M92.059,78.725c0,0-3.067,10.331-6.388,12.085c-1.533,0-2.683,0-2.683,0l-3.32-4.705l-7.281-2.029
				v15.684h9.453l9.452-8.488L92.059,78.725z"/>
			<path style="fill:#FFFFFF;" d="M75.755,89.241c0,0-0.5-2.226-3.417-2.226c-2.667,0-3.417,2.226-3.417,2.226H75.755z"/>
			<path style="fill:#915F2C;" d="M51.42,62.366c0,0,2.783,0.579,4.581,2.669c-0.317-3.657-1.02-15.805,3.42-18.853
				c4.44-3.047,6.517,0.131,12.966,0.131c0-4.789,0-8.882,0-8.882s-7.751,0-10.482,0c-2.732,0-11.401,1.306-11.401,9.491
				C50.503,55.108,51.42,62.366,51.42,62.366z"/>
			<path style="fill:#915F2C;" d="M93.353,62.366c0,0-2.783,0.579-4.581,2.669c0.317-3.657,1.02-15.805-3.42-18.853
				c-4.439-3.047-6.517,0.131-12.966,0.131c0-4.789,0-8.882,0-8.882s7.751,0,10.482,0c2.732,0,11.401,1.306,11.401,9.491
				C94.27,55.108,93.353,62.366,93.353,62.366z"/>
		</g>
	</g>
</g>
`]
