import React from "react";
import { LoadLazy } from "@banklampung/components";

const Col = ({ components, maxItem }) => {
  const style = {
    flex: `1 0 ${parseFloat(100 / maxItem).toFixed(2)}%`,
    padding: "0px 5px",
  };

  return (
    <div className="d-flex flex-wrap">
      <LoadLazy>
        {components.map((component, index) => (
          <div style={style} key={index}>
           {component}
          </div>
        ))}
      </LoadLazy>
    </div>
  );
};

Col.defaultProps = {
  components: [],
  maxItem: 2,
};

export default Col;
