import { LoadLazy, StaticCardForm } from "@banklampung/components";
import _ from "@banklampung/libs/lodash";
import { history } from "@banklampung/utils";
import { CNav, CNavItem, CNavLink } from "@coreui/react";
import React, { memo, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

const BasicNavTab = (props) => {
  const { formName, routes, location, match, onClickTab } = props;

  const getUrl = (tabType) => {
    const params = match.params;
    let path = match.path;

    _.forEach(params, function (value, key) {
      path = path.replaceAll(`:${key}`, key === "tabType" ? tabType : value);
    });

    return path;
  };

  const [activeNav, setActiveNav] = useState(() => {
    const defNav = _.find(
      routes,
      (route) => getUrl(route.key) === location.pathname
    );
    return !_.isEmpty(defNav) ? defNav.key : "";
  });

  const toggleNav = (key) => {
    if (activeNav !== key) {
      setActiveNav(key);
    }
  };

  return (
    <StaticCardForm
      formName={formName}
      body={
        <Router history={history}>
          <CNav variant="pills" justified>
            {routes.map((route) => (
              <CNavItem key={route.key}>
                <CNavLink
                  to={getUrl(route.key)}
                  active={activeNav === route.key}
                  onClick={() => {
                    toggleNav(route.key);

                    if (onClickTab) {
                      onClickTab();
                    }
                  }}
                >
                  {route.name}
                </CNavLink>
              </CNavItem>
            ))}
          </CNav>

          <div className="mt-3">
            <LoadLazy>
              <Switch>
                {routes.map((route) => (
                  <Route
                    key={route.key}
                    path={getUrl(route.key)}
                    exact={route.exact}
                    name={route.name}
                    render={(props) => (
                      <route.component
                        navigationTitle={route.name}
                        navigationKey={route.key}
                        {...props}
                      />
                    )}
                  />
                ))}
              </Switch>
            </LoadLazy>
          </div>
        </Router>
      }
    />
  );
};

BasicNavTab.defaultProps = {
  routes: [],
};

export default memo(BasicNavTab);
