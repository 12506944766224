import axios from "axios";
import { successHandler, errorHandler } from "./errorHandler";
import { getDeviceHeader } from "./device";
import { apiUrl, timeoutInMilis, appVersion, appHeader } from "@banklampung/config";

const commonHeaders = {
  "Content-Type": "application/json",
};

const setHeaders = () => {
  if(appHeader){
    const blpgDevice = getDeviceHeader();
    const {deviceId, deviceModel, clientIp, operatingSystem} = blpgDevice;
    return {
      ...commonHeaders,
      "X-BLPG-AppVersion": appVersion,
      "X-BLPG-OperatingSystem": operatingSystem,
      "X-BLPG-Deviceid": deviceId,
      "X-BLPG-DeviceModel": deviceModel,
      "X-BLPG-ClientIP": clientIp,
    }
  }else{
    return commonHeaders;
  }
}

const hostInstance = axios.create({
  timeout: timeoutInMilis,
  baseURL: apiUrl,
  headers: setHeaders(),
});

hostInstance.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error)
);

export default hostInstance;
