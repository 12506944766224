import React from "react";
import { useDispatch } from "react-redux";
import {
  IconButton,
  Authorization,
  TextCenter,
  ButtonLink,
} from "@banklampung/components";
import * as GlobalActions from "@banklampung/store/actions";

const ActionBtnTable = ({
  urlShow,
  onClickDelete,
  onClickEdit,
  onClickShow,
  textDelete,
  editPermissions,
  deletePermissions,
}) => {
  const dispatch = useDispatch();

  const handleEdit = () => {
    if (onClickEdit) {
      onClickEdit();
    }
  };

  const handleDelete = () => {
    dispatch(
      GlobalActions.openDialog({
        icon: "question",
        text: textDelete || `Anda yakin ingin menghapus?`,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Ya. Saya yakin!",
        onConfirm: () => {
          dispatch(GlobalActions.closeDialog());

          if (onClickDelete) {
            onClickDelete();
          }
        },
        onCancel: () => {
          dispatch(GlobalActions.closeDialog());
        },
      })
    );
  };

  return (
    <TextCenter
      value={
        <>
          {urlShow && (
            <>
              <ButtonLink
                link={urlShow}
                onClick={onClickShow}
                propsButton={{
                  propsIcon: { name: "cil-external-link" },
                  color: "primary",
                  size: "sm",
                  label: "Lihat",
                }}
              />{" "}
            </>
          )}
          <IconButton
            permissions={editPermissions}
            propsIcon={{ name: "cil-pencil" }}
            color="secondary"
            size="sm"
            label="Edit"
            onClick={handleEdit}
          />{" "}
          <IconButton
            permissions={deletePermissions}
            propsIcon={{ name: "cil-trash" }}
            color="danger"
            size="sm"
            label="Hapus"
            onClick={handleDelete}
          />
        </>
      }
    />
  );
};

ActionBtnTable.defaultProps = {
  editPermissions: [],
  deletePermissions: [],
};

export default ActionBtnTable;
