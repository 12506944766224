import React from "react";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { withSwalInstance } from "@banklampung/libs/sweetalert";

const SweetReactContent = withReactContent(Swal);

const SweetInstance = withSwalInstance(SweetReactContent);

const SweetDialog = props => {
  const show = useSelector(({ dialog }) => dialog.state);
  const options = useSelector(({ dialog }) => dialog.options);

  return <SweetInstance 
    {...options} 
    show={show} 
    reverseButtons={true} 
    cancelButtonClass={"mr-1"}
    confirmButtonClass={"ml-1"}
  />;
};

export default SweetDialog;
