import { Field } from "@banklampung/components";
import CIcon from "@coreui/icons-react";
import {
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
} from "@coreui/react";
import React from "react";

const FieldIcon = (props) => {
  const { icon, ...pr } = props;

  return (
    <CInputGroup className="mb-3">
      <CInputGroupPrepend>
        <CInputGroupText>
          <CIcon name={icon} />
        </CInputGroupText>
      </CInputGroupPrepend>

      <Field {...pr} />
    </CInputGroup>
  );
};

export default FieldIcon;
