import { Card, FieldIcon, LoaderButton } from "@banklampung/components";
import {
  appDescription,
  appName,
  appVersion,
  clientId,
  clientSecret,
  envName,
  isDev,
  isCopyPasteForm
} from "@banklampung/config";
import { useForm, useGetApi, usePostApi } from "@banklampung/hooks";
import * as GlobalActions from "@banklampung/store/actions";
import { encryptor, history } from "@banklampung/utils";
import CIcon from "@coreui/icons-react";
import { CBadge, CCol, CContainer, CForm, CRow } from "@coreui/react";
import PropTypes from "prop-types";
import queryString from "query-string";
import React from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";

const Login = (props) => {
  const { companyName, departementName, logo, onSuccess } = props;
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const [, doFetchMe] = useGetApi({
    onSuccess: ({ payload }) => {
      dispatch(GlobalActions.setUserData(payload));

      if (payload.resetPassword) {
        toast.success(
          `Login berhasil. Hi ${
            payload.fullName || payload.username
          } Ganti Password Sekarang yuuuk...`
        );
        history.push({
          pathname: "/change-password",
        });
      } else {
        const redirectPath = searchParams.get("redirect_path");

        toast.success(
          `Login berhasil. Selamat datang ${
            payload.fullName || payload.username
          }`
        );
        history.push({
          pathname: redirectPath || "/",
        });
      }
    },
  });

  const [{ isLoading: isLoadingLogin }, doSubmitLogin] = usePostApi({
    responseFormatted: "login",
    onSuccess: ({ payload }) => onSuccessLogin(payload),
  });

  const { form, handleChange, handleSubmit } = useForm({
    initialState: {
      username: "",
      password: "",
    },
    onSubmit: () => {
      onSubmitLogin();
    },
  });

  const onSubmitLogin = () => {
    doSubmitLogin({
      url: "auth/oauth/token",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      auth: {
        username: clientId,
        password: encryptor.decrypt(clientSecret),
      },
      body: queryString.stringify({
        username: form.username,
        password: form.password,
        grant_type: "password",
      }),
    });
  };

  const onSuccessLogin = (payload) => {
    GlobalActions.loginSuccess(payload);

    setTimeout(() => {
      doFetchMe({
        url: "auth/users/me",
      });
    }, 100);

    if (onSuccess) {
      onSuccess();
    }
  };

  const isCopyPasteFormCheck = (e) => {
    if(isCopyPasteForm === "DISABLE"){
      e.preventDefault();
      return false;
    }
  }

  return (
    <div className="c-app c-default-layout flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="5">
            <Card className="p-4">
              <CForm
                onSubmit={handleSubmit}
                name="formLogin"
                className="d-flex flex-column"
              >
                <CIcon
                  name={logo}
                  width="220"
                  height="80"
                  className="align-self-center"
                  alt="logo-bank-lampung"
                />
                <h2 className="mt-2 align-self-center">{appName}</h2>

                <h6 className="align-self-center">{appDescription}</h6>

                {isDev ? (
                  <CBadge color="dark" className="align-self-center">
                    {envName}
                  </CBadge>
                ) : (
                  <CBadge color="primary" className="align-self-center">
                    {envName}
                  </CBadge>
                )}

                <p className="text-muted mt-3 mb-4 align-self-center">
                  Sign In to start your session
                </p>

                <FieldIcon
                  id="username"
                  label="Username"
                  placeholder="Username"
                  icon="cil-user"
                  type="input"
                  validate={`["required"]`}
                  invalid={form.invalids.username}
                  errorMessage={form.errorMessages.username}
                  onChange={handleChange}
                  defVal={form.username}
                  autoComplete="off"
                  onCut={isCopyPasteFormCheck}
                  onCopy={isCopyPasteFormCheck}
                  onPaste={isCopyPasteFormCheck}
                  onDrag={isCopyPasteFormCheck}
                  onDrop={isCopyPasteFormCheck}
                  onSelectStart={isCopyPasteFormCheck}
                />

                <FieldIcon
                  id="password"
                  label="Password"
                  placeholder="Password"
                  icon="cil-lock-locked"
                  type="input"
                  fieldType="input"
                  validate={`["required"]`}
                  invalid={form.invalids.password}
                  errorMessage={form.errorMessages.password}
                  onChange={handleChange}
                  defVal={form.password}
                  className={"mask-password"}
                  autoComplete="off"
                  onCut={isCopyPasteFormCheck}
                  onCopy={isCopyPasteFormCheck}
                  onPaste={isCopyPasteFormCheck}
                  onDrag={isCopyPasteFormCheck}
                  onDrop={isCopyPasteFormCheck}
                  onSelectStart={isCopyPasteFormCheck}
                />

                <LoaderButton
                  propsIcon={{ name: "cil-paper-plane" }}
                  label="Login"
                  isLoading={isLoadingLogin}
                  className="align-self-start mt-1"
                />

                <div className="d-flex flex-column mt-5 align-self-center align-items-center">
                  <span>
                    {`${new Date().getFullYear()} @ ${departementName} - ${companyName}`}
                  </span>
                  <small className="text-muted">{`Versi ${appVersion}`}</small>
                </div>
              </CForm>
            </Card>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

Login.propTypes = {
  companyName: PropTypes.string,
  departementName: PropTypes.string,
  logo: PropTypes.string,
};

Login.defaultProps = {
  companyName: "PT Bank Lampung",
  departementName: "Divisi Teknologi Informasi",
};

export default Login;
