import { CSelect } from "@coreui/react";
import React, { memo } from "react";

const PaginationDropdown = ({ pages, pageSize, onChange }) => {
  return (
    <div>
      <CSelect
        custom
        value={pageSize}
        onChange={(e) => onChange(Number(e.target.value))}
      >
        {pages.map((size) => (
          <option key={size} value={size}>
            Tampilkan {size}
          </option>
        ))}
      </CSelect>
    </div>
  );
};

PaginationDropdown.defaultProps = {
  pages: [10, 25, 50, 100]
}

export default memo(PaginationDropdown);
