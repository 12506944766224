import PropTypes from "prop-types";
import React from "react";
import { CFormGroup, CLabel } from "@coreui/react";
import { ColTwo } from "@banklampung/components";

const FieldTicket = ({ leftValue, rightValue }) => {
  return (
    <CFormGroup row className="mb-0">
      <ColTwo
        propsColA={{ md: 5, className: "text-right" }}
        propsColB={{ md: 7 }}
        childA={<CLabel className="billing-code-txt">{leftValue}: </CLabel>}
        childB={
          <p className="form-control-static billing-code-txt billing-code">
            {rightValue}
          </p>
        }
      />
    </CFormGroup>
  );
};

FieldTicket.propTypes = {
  leftValue: PropTypes.string.isRequired,
  rightValue: PropTypes.string.isRequired
};

export default FieldTicket;
