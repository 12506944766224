import React, { useMemo } from "react";
import MaskedInput from "react-text-mask";
import { MaskingHelper } from "@banklampung/helper";

const FieldAlphaNum = ({ typeMask, param, validate, className, ...props }) => {
  const mask = useMemo(() => MaskingHelper.to(typeMask),[]);

  return (
    <MaskedInput
      mask={mask}
      className={className}
      data-validate={validate}
      data-param={param}
      {...props}
    />
  );
};

export default FieldAlphaNum;
