import useApi from "./useApi";

const useDeleteApi = (props) => {
  const result = useApi({
    method: "delete",
    progressDialog: {
      show: true,
    },
    ...props,
  });
  return result;
};

export default useDeleteApi;
