const CUSTOMER_INQUIRY  = "amil/v1/customers";
const CUSTOMER_STORE    = "amil/v1/customers";
const CUSTOMER_DETAIL   = "amil/v1/customers/find/:id";
const CUSTOMER_AGENCY   = "amil/v1/agencies";
const CUSTOMER_ENDPOINT = "amil/v1/customers";

export default {
    CUSTOMER_INQUIRY,
    CUSTOMER_STORE,
    CUSTOMER_DETAIL,
    CUSTOMER_AGENCY,

    CUSTOMER_ENDPOINT
};