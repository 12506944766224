import { Authorization } from "@banklampung/components";
import { history } from "@banklampung/utils";
import CIcon from "@coreui/icons-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CCard, CCol, CCardBody, CCardFooter, CCardText } from "@coreui/react";
import PropTypes from "prop-types";
import React from "react";
import "./style.scss";
import { notEmpty } from "@banklampung/helper";

const Content = ({ title, icon, link, faIcon }) => {
  const onClick = (event) => {
    event.preventDefault();

    history.push(link);
  };

  return (
    <CCard className="h-110" onClick={onClick}>
      <CCardBody>
        <CCardText >
          <div className="d-flex justify-content-center">
            {notEmpty(faIcon) ? (
              <FontAwesomeIcon 
                icon={faIcon} 
                size="3x"
              />
            ) : (
              <CIcon
                name={icon}
                width="32"
                height="32"
                alt="icon"
              />
            )}
          </div>
        </CCardText>
      </CCardBody>
      <CCardFooter className={"d-flex justify-content-center"}>
        <span className="title">{title}</span>
      </CCardFooter>
    </CCard>
  );
};

const Menu = (props) => {
  const { key, permissions, rolesPermissions, colLength } = props;

  return (permissions.length == 0 && rolesPermissions.length == 0) ? (
    <CCol lg={ colLength ?? "3" } md={ colLength ?? "3" } sm="6" xs="6" key={key}>
      <Content {...props} />
    </CCol>
  ) : (
    <Authorization permissions={permissions} rolesPermissions={rolesPermissions}>
      <CCol lg={ colLength ?? "3" } md={ colLength ?? "3" } sm="6" xs="6" key={key}>
        <Content {...props} />
      </CCol>
    </Authorization>
  );
};

Menu.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string,
  link: PropTypes.string,
};

Menu.defaultProps = {
  permissions: [],
  rolesPermissions: [],
  colLength: 3
};

export default Menu;
