import {
  Card,
  FooterSubmitForm,
  LoaderButton,
  LoadLazy,
} from "@banklampung/components";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";

const BasicForm = ({
  children,
  onSubmit,
  formName,
  formLabel,
  formSubLabel,
  formLabelOverride,
  body,
  buttonSubmit,
  slotButton,
  slotButtonBefore,
  slotButtonAfter,
  buttonAlign,
  propsCard,
  outsideEnd,
  delayBtnActive,
  hideButton
}) => {
  const refBtnSubmit = useRef();
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (disabled) {
      setTimeout(() => {
        setDisabled(false);
      }, delayBtnActive);
    }
  }, [disabled]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setDisabled(true);

    if (!buttonSubmit.disabled) {
      refBtnSubmit.current.click();
    }
  };

  return (
    <Card
      headerSlot={
        <>
          {formLabelOverride ? (
            <strong>{formLabelOverride}</strong>
          ) : (
            <strong>
              Form {formLabel} <small>{formSubLabel}</small>
            </strong>
          )}
        </>
      }
      footerSlot={
        <>
          <FooterSubmitForm
            button={
              slotButton ? (
                <>
                  { hideButton == false && (slotButton) }
                </>
              ) : (
                <>
                {slotButtonBefore}
                {hideButton == false && (
                  <LoaderButton
                    {...buttonSubmit}
                    type="button"
                    disabled={disabled}
                    onClick={handleSubmit}
                  />
                )}
                {slotButtonAfter}
                </>
              )
            }
            align={buttonAlign}
          />
        </>
      }
      {...propsCard}
    >
      <LoadLazy>
        <form onSubmit={onSubmit} name={formName} id={formName}>
          {body || children}

          <button
            type="submit"
            ref={refBtnSubmit}
            style={{
              display: "none",
            }}
          ></button>
        </form>
        {outsideEnd}
        <div className="required">* Mandatory</div>
      </LoadLazy>
    </Card>
  );
};

BasicForm.propTypes = {
  onSubmit: PropTypes.any.isRequired,
  formName: PropTypes.string.isRequired,
  body: PropTypes.any,
  buttonSubmit: PropTypes.any.isRequired,
};

BasicForm.defaultProps = {
  delayBtnActive: 2000,
  propsCard: {},
  buttonSubmit: {
    disabled: false,
  },
  hideButton: false
};

export default BasicForm;
