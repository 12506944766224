const merge = require("lodash/merge");

const app = {
  all: {
    env: process.env.NODE_ENV || "development",
    isDev: process.env.NODE_ENV !== "production",
    basename: process.env.PUBLIC_PATH,
    isBrowser: typeof window !== "undefined",
    isCopyPasteForm: process.env.REACT_APP_COPY_PASTE_FORM || "DISABLE",
    key: process.env.REACT_APP_KEY || "secret",
    appName: process.env.REACT_APP_NAME || "React App",
    appHeader: process.env.REACT_APP_HEADER_DEVICE || false,
    statusMFA: process.env.REACT_APP_STATUS_MFA || "DISABLE",
    envName: process.env.REACT_APP_ENV_NAME || "Development",
    appDescription: process.env.REACT_APP_DESCRIPTION || "",
    appVersion: process.env.REACT_APP_VERSION || "",
    clientId: process.env.REACT_APP_CLIENT_ID || "CLIENT-ID",
    clientSecret: process.env.REACT_APP_CLIENT_SECRET || "CLIENT-SECRET",
    clientKey: process.env.REACT_APP_CLIENT_KEY || "CLIENT-KEY",
    credentialKey: process.env.REACT_APP_CREDENTIAL_KEY || "CREDENTIAL-KEY",
    tripleDesKey: process.env.REACT_APP_TRIPLE_DES_KEY || "TRIPLE-DES-KEY",
    ocraKey: process.env.REACT_APP_OCRA_KEY || "CREDENTIAL-KEY",
    ayunaKey: process.env.REACT_APP_AYUNA_KEY || "AYUNA-KEY",
    channelId: process.env.REACT_APP_CHANNEL_ID || "Channel-Id",
    zoneDateTime: process.env.REACT_APP_ZONE_DATE_TIME || "Asia/Jakarta",
    sessionKey: process.env.REACT_APP_SESSION_KEY || "app_session",
    apiUrl: process.env.REACT_APP_API_URL || "http://localhost:8080",
    streamUrl: process.env.REACT_APP_STREAM_URL || "http://localhost:8080",
    googleApiKey: process.env.REACT_APP_GOOGLE_API_KEY || "",
    mapLat: parseFloat(process.env.REACT_APP_MAP_LAT || -5.450000),
    mapLng: parseFloat(process.env.REACT_APP_MAP_LNG || 105.266670),    
    reasonablePayWait: parseInt(process.env.REACT_APP_REASONABLE_PAY_WAIT || 15000),    
    timeoutInMilis: parseInt(process.env.REACT_APP_TIMEOUT_IN_MILIS || 60000),
    sessionTimeoutInMilis: parseInt(process.env.REACT_APP_SESSION_TIMEOUT_IN_MILIS || 120000),    
  },
  test: {},
  development: {},
  production: {},
};

module.exports = merge(app.all, app[app.all.env]);
