import React from "react";
import PropTypes from "prop-types";
import { LoaderButton } from "@banklampung/components";
import { apiUrl } from "@banklampung/config";
import { useGetApi } from "@banklampung/hooks";
import FileSaver from "file-saver";

const ButtonDownload = ({ url, label, fileName }) => {
  const [{ isLoading: isLoadingDownload }, doDownload] = useGetApi({
    convertCamelCase: false,
    onSuccess: ({ payload }) => onSuccessDownload(payload)
  });

  const handleDownload = () => {
    doDownload({
      url: `${apiUrl}/${url}`,
      responseType: "blob",
      params: {
        time: new Date().getTime()
      }
    });
  };

  const onSuccessDownload = payload => {
    FileSaver.saveAs(payload, fileName);
  };

  return (
    <LoaderButton
      color="dark"
      className="btn-sm"
      onClick={handleDownload}
      propsIcon={{ name: "cil-cloud-download" }}
      label={label || "Download"}
      isLoading={isLoadingDownload}
    />
  );
};

ButtonDownload.propTypes = {
  url: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired
};

export default ButtonDownload;
