const DISBURSEMENT_ENDPOINT                 = "ayuna/disbursement-governments";
const TRANSACTION_ENDPOINT                  = "ayuna/transactions";
const MENU_ENDPOINT                         = "ayuna/menus";
const PRODUCT_ENDPOINT                      = "ayuna/products";
const CORPORATE_ENDPOINT                    = "ayuna/corporates";
const ACCOUNT_ENDPOINT                      = "ayuna/accounts";
const BENEFICARY_ENDPOINT                   = "ayuna/government-beneficiaries";
const PROFILE_ENDPOINT                      = "ayuna/profiles";
const USERGROUP_ENDPOINT                    = "ayuna/user-groups";
const LIMIT_ENDPOINT                        = "ayuna/authorize-limits";

const AYUNA_ACCOUNT_BULK_ENDPOINT          = "ayuna/accounts/bulk";
const AYUNA_ACCOUNT_ENDPOINT               = "ayuna/accounts";
const AYUNA_CORPORATE_ENDPOINT             = "ayuna/corporates";
const AYUNA_CORPORATE_MENU_ENDPOINT        = "ayuna/corporate-menus";
const AYUNA_MENU_ENDPOINT                  = "ayuna/menus";
const AYUNA_PROFILE_ENDPOINT               = "ayuna/profiles";
const AYUNA_VERIFY_ENDPOINT                = "ayuna/authorize-transactions";
const AYUNA_TASK_ENDPOINT                  = "ayuna/tasks";
const AYUNA_TASK_FLOW_ENDPOINT             = "ayuna/task-flows";
const AYUNA_TEMPLATES_MENU_ENDPOINT        = "ayuna/templates";
const AYUNA_BENEFICARY_ENDPOINT            = "ayuna/government-beneficiaries";
const AYUNA_BENEFICARY_ATTACHMENT_ENDPOINT = "ayuna/attachments";
const AYUNA_MASTER_DEDUCTION_ENDPOINT      = "ayuna/master/deductions";

// const AYUNA_URL_TEMPLATE_ENDPOINT          = "http://192.168.188.38/ayuna/template/Template%20Rek%20Penerima%20L-Finns.zip";
const AYUNA_URL_TEMPLATE_ENDPOINT          = "https://assets.banklampung.co.id/ayuna-dev/template/Template%20Rek%20Penerima%20L-Finns.zip";

export default {
  DISBURSEMENT_ENDPOINT,
  TRANSACTION_ENDPOINT,
  MENU_ENDPOINT,
  PRODUCT_ENDPOINT,
  CORPORATE_ENDPOINT,
  ACCOUNT_ENDPOINT,
  BENEFICARY_ENDPOINT,
  PROFILE_ENDPOINT,
  USERGROUP_ENDPOINT,
  LIMIT_ENDPOINT,

  AYUNA_ACCOUNT_BULK_ENDPOINT,
  AYUNA_ACCOUNT_ENDPOINT,
  AYUNA_CORPORATE_ENDPOINT,
  AYUNA_CORPORATE_MENU_ENDPOINT,
  AYUNA_MENU_ENDPOINT,
  AYUNA_PROFILE_ENDPOINT,
  AYUNA_VERIFY_ENDPOINT,
  AYUNA_TASK_ENDPOINT,
  AYUNA_TASK_FLOW_ENDPOINT,
  AYUNA_TEMPLATES_MENU_ENDPOINT,
  AYUNA_BENEFICARY_ENDPOINT,
  AYUNA_BENEFICARY_ATTACHMENT_ENDPOINT,
  AYUNA_URL_TEMPLATE_ENDPOINT,
  AYUNA_MASTER_DEDUCTION_ENDPOINT
};
