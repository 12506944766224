import { usePostApi } from "@banklampung/hooks";
import * as Actions from "@banklampung/store/actions";
import { history } from "@banklampung/utils";
import CIcon from "@coreui/icons-react";
import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CNavbarBrand,
} from "@coreui/react";
import PropTypes from "prop-types";
import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AuthEndpoint } from "@app-endpoint";
import { HmacHelper } from "@banklampung/helper";
import { jwtService } from "@banklampung/services";
import * as GlobalActions from "@banklampung/store/actions";

const HeaderDropdown = (props) => {
  const { avatar } = props;
  const dispatch = useDispatch();
  const auth = useSelector(({ auth }) => auth);

  const [, doSubmitLogout] = usePostApi({
    errorHandler: false,
    onSuccess: () => {
      dispatch(Actions.logoutUser());
    },
    onError: ({ response }) => {
      const { data={} } = response || {};
      const { error_code="", message={} } = data;

      if(error_code === 'CORE-999'){
        dispatch(Actions.logoutUser());
      }else{
        // dispatch(
        //   GlobalActions.openDialog({
        //     icon: "error",
        //     title: `${error_code}`,
        //     text: `${message.indonesian || ""}`,
        //     showConfirmButton: true,
        //     onConfirm: () => {
        //       dispatch(GlobalActions.closeDialog());
        //     },
        //   })
        // );
        console.log("debug-err: ", response)
      }
    }
  });

  const signOut = (event) => {
    event.preventDefault();

    (async () => {
      const url = `/${AuthEndpoint.LOGOUT_ENDPOINT ?? "auth/oauth/revoke"}`;
      const signHeaders = await HmacHelper.getHeader({
        method: "POST",
        path: url
      });

      doSubmitLogout({
        url: url,
        headers: signHeaders,
      });
    })();
  };

  const openProfile = (event) => {
    event.preventDefault();

    history.push("/profile");
  };

  const openChangePassword = (event) => {
    event.preventDefault();

    history.push("/change-password");
  };

  // Start: Timmer
  const Ref = useRef(null);
  const [timer, setTimer] = useState("00:00:00");
  const getTimeRemaining = (e) => {
        const total =
            Date.parse(e) - Date.parse(new Date());
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor(
            (total / 1000 / 60) % 60
        );
        const hours = Math.floor(
            (total / 1000 / 60 / 60) % 24
        );
        return {
            total,
            hours,
            minutes,
            seconds,
        };
    };
 
    const startTimer = (e) => {
        let { total, hours, minutes, seconds } =
            getTimeRemaining(e);
        if (total >= 0) {
            setTimer(
                (hours > 9 ? hours : "0" + hours) +
                    ":" +
                    (minutes > 9
                        ? minutes
                        : "0" + minutes) +
                    ":" +
                    (seconds > 9 ? seconds : "0" + seconds)
            );
        }
    };
 
    const clearTimer = (e) => {
        setTimer("00:00:10");
        if (Ref.current) clearInterval(Ref.current);
        const id = setInterval(() => {
            startTimer(e);
        }, 1000);
        Ref.current = id;
    };
 
    const getDeadTime = () => {
        let deadline = new Date();
        const current = (deadline.getTime() / 1000);
        const expired = jwtService.getExp();
        const timeSecond = expired - current;
        console.log("debug-current", current)
        console.log("debug-expired", expired)

        console.log("debug-timeSecond", timeSecond)
        deadline.setSeconds(deadline.getSeconds() + timeSecond);
        return deadline;
        
    };

    useEffect(() => {
      clearTimer(getDeadTime());
    }, []);

    const onClickReset = () => {
      clearTimer(getDeadTime());
    };
    // Finish: Timmer

  return (
    <CDropdown inNav className="c-header-nav-items mx-2" direction="down">
      <CDropdownToggle className="c-header-nav-link" caret={false}>
        <CNavbarBrand
          style={{
            fontSize: "14px",
          }}
        >
          Waktu Sesi: {timer} | {auth.username ?? ""} | {auth.fullName ?? ""}
        </CNavbarBrand>
        <div className="c-avatar">
          <CIcon
            name={avatar}
            size="3xl"
            className="c-avatar-img"            
          />
        </div>
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
        <CDropdownItem header tag="div" color="light" className="text-center">
          <strong>Akun</strong>
        </CDropdownItem>
        <CDropdownItem onClick={openProfile}>
          <CIcon icon="cil-user" className="mr-2" size="md"/> Profil
        </CDropdownItem>
        <CDropdownItem onClick={openChangePassword}>
          <CIcon icon="cil-lock-locked" className="mr-2" size="md" /> Ganti Password
        </CDropdownItem>
        <CDropdownItem divider />
        <CDropdownItem onClick={signOut}>
          <CIcon icon="cil-account-logout" className="mr-2" size="md" /> Keluar
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  );
};

HeaderDropdown.propTypes = {
  avatar: PropTypes.string,
};

HeaderDropdown.defaultProps = {
  avatar: "",
};

export default HeaderDropdown;
