import * as GlobalActions from "@banklampung/store/actions";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

const MinimizeSidebar = ({ children, ...attr }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GlobalActions.minimizeSidebar());
  }, []);

  return <React.Fragment {...attr}>{children}</React.Fragment>;
};

export default MinimizeSidebar;
