const eventTypes = [
  "keypress",
  "mousemove",
  "mousedown",
  "scroll",
  "touchmove",
  "pointermove",
];

export const addEventListeners = (listener) => {
  eventTypes.forEach((type) => {
    window.addEventListener(type, listener, false);
  });
};

export const removeEventListeners = (listener) => {
  if (listener) {
    eventTypes.forEach((type) => {
      window.removeEventListener(type, listener, false);
    });
  }
};

export { default as SweetDialog } from "./SweetDialog";
export { default as history } from "./history";
export { default as AppUtils } from "./AppUtils";
export { default as encryptor } from "./encryptor";
export { default as FormValidator } from "./FormValidator";
export { default as LocalStorage } from "./LocalStorage"
