import React from "react";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import MaskedInput from "react-text-mask";

const FieldCurrency = ({ value, prefix="Rp ", ...props}) => {
  
  return (
    <MaskedInput
      mask={createNumberMask({
        prefix: `${prefix} `,
        thousandsSeparatorSymbol: ".",
        decimalSymbol: ",",
        includeThousandsSeparator: true,
        allowDecimal: true,
        decimalLimit: 2,
        allowNegative: false,
        allowLeadingZeroes: false,
      })}
      data-is-currency="1"
      value={value}
      {...props}
    />
  );
};

export default FieldCurrency;
