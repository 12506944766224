import { CPagination } from "@coreui/react";
import React, { memo } from "react";

const BasicPagination = ({ pageIndex, pageCount, onActivePageChange }) => {
  return (
    <div className="d-flex flex-column align-items-end">
      <CPagination
        align="end"
        pages={pageCount}
        activePage={pageIndex}
        onActivePageChange={onActivePageChange}
      ></CPagination>
      <span>
        Menampilkan{" "}
        <strong>
          {pageIndex} dari {pageCount}
        </strong>{" "}
        halaman
      </span>
    </div>
  );
};

export default memo(BasicPagination);
