import { HeaderDropdown } from "@banklampung/components";
import * as GlobalActions from "@banklampung/store/actions";
import CIcon from "@coreui/icons-react";
import {
  CBreadcrumbRouter,
  CHeader,
  CHeaderBrand,
  CHeaderNav,
  CHeaderNavItem,
  CHeaderNavLink,
  CSubheader,
  CToggler,
} from "@coreui/react";
import PropTypes from "prop-types";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

const Header = (props) => {
  const { routes, avatar, brand, dark, disableMenu=false } = props;
  const dispatch = useDispatch();
  const sidebarShow = useSelector(({ sidebar }) => sidebar.state);

  const toggleSidebar = () => {
    const val = [true, "responsive"].includes(sidebarShow)
      ? false
      : "responsive";
    dispatch({ type: GlobalActions.CHANGE_SIDEBAR, payload: val });
  };

  const toggleSidebarMobile = () => {
    const val = [false, "responsive"].includes(sidebarShow)
      ? true
      : "responsive";
    dispatch({ type: GlobalActions.CHANGE_SIDEBAR, payload: val });
  };

  return (
    <CHeader className={(dark && "c-header-dark")} withSubheader >
      <CToggler
        inHeader
        className="ml-md-3 d-lg-none"
        onClick={toggleSidebarMobile}
      />
      <CToggler
        inHeader
        className="ml-3 d-md-down-none"
        onClick={toggleSidebar}
      />
      <CHeaderBrand className="mx-auto d-lg-none" to="/">
        <CIcon name={brand} height="40" alt="logo-bank-lampung" />
      </CHeaderBrand>

      <CHeaderNav className="d-md-down-none mr-auto">
        {disableMenu == false && (
          <>
            <CHeaderNavItem className="px-3">
              <CHeaderNavLink to="/about">Tentang</CHeaderNavLink>
            </CHeaderNavItem>
            <CHeaderNavItem className="px-3">
              <CHeaderNavLink to="/guide">Pedoman Penggunaan</CHeaderNavLink>
            </CHeaderNavItem>
          </>
        )}
      </CHeaderNav>

      <CHeaderNav className="px-3">
        <HeaderDropdown avatar={avatar} />
      </CHeaderNav>

      <CSubheader className="px-3 justify-content-between">
        <CBreadcrumbRouter
          className="border-0 c-subheader-nav m-0 px-0 px-md-3"
          routes={routes}
        />
      </CSubheader>
    </CHeader>
  );
};

Header.propTypes = {
  routes: PropTypes.array,
  avatar: PropTypes.string,
  brand: PropTypes.string,
};

Header.defaultProps = {
  routes: [],
};

export default Header;
