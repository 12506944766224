const INQUIRY_ENDPOINT = "arau/inquiry";
const PAYMENT_ENDPOINT = "arau/payments";
const CLUSTER_ENDPOINT = "arau/clusters";
const SLIP_ENDPOINT    = "arau/slip";

export default {
  INQUIRY_ENDPOINT,
  PAYMENT_ENDPOINT,
  CLUSTER_ENDPOINT,
  SLIP_ENDPOINT
};
