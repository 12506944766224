import {
  BasicForm,
  BasicList,
  ColTwo,
  FieldGroup,
} from "@banklampung/components";
import _ from "@banklampung/libs/lodash";
import React, { useEffect, useState } from "react";

const FilterNavigation = ({
  name,
  slotContent,
  slotFilter,
  handleChange,
  handleSubmit,
  isLoading,
  defNavType,
  navTypes,
  createPermission,
  onClickBtn,
}) => {
  const [navType, setNavType] = useState(defNavType);

  useEffect(() => {
    setNavType(_.find(navTypes, { value: defNavType.value }));
  }, [defNavType.value]);

  return (
    <ColTwo
      propsRow={{
        className: "flex-column-reverse flex-md-row",
      }}
      propsColA={{ md: 9 }}
      propsColB={{ md: 3 }}
      childA={
        <BasicList
          resourceName={!_.isEmpty(navType) && navType.name}
          createPermission={createPermission}
          historyLink={!_.isEmpty(navType) && navType.addLink}
          onClickBtn={onClickBtn}
          body={slotContent}
        />
      }
      childB={
        <BasicForm
          onSubmit={handleSubmit}
          formName="formFilter"
          formLabel={`Filter ${name}`}
          formIcon="filter"
          body={
            <div className="d-flex flex-column">
              <FieldGroup
                id="navType"
                label={name}
                type="input"
                fieldType="select"
                isRequired={true}
                validate={`["required"]`}
                onChange={handleChange}
                options={navTypes}
                value={!_.isEmpty(defNavType) && defNavType.value}
                disableDefOpts
              />

              {slotFilter}
            </div>
          }
          buttonSubmit={{
            propsIcon: { name: "cil-filter" },
            label: "Filter",
            isLoading: isLoading,
          }}
          buttonAlign="center"
        />
      }
    />
  );
};

FilterNavigation.propTypes = {};

export default FilterNavigation;
