import { BLPG_LOGIN_STATUS, BLPG_MFA_TOKEN, KEY_DEVICE_IP } from "@banklampung/config"

class LocalStorage {
  clear = () => {
    localStorage.clear();
  }

  set = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
  };

  remove = (key) => {
    localStorage.removeItem(key);
  };

  get = (key) => {
    return localStorage.getItem(key);
  };

  getObj = (key) => {
    return JSON.parse(this.get(key));
  };

  sets = (items) => {
    items.forEach((elm) => {
      this.set(elm.id, elm.value);
    });
  };


  setLocalStorageLoginSuccess = () => {
    localStorage.setItem(BLPG_LOGIN_STATUS, 1);
  }
  setLocalStorageLoginInit = () => {
    localStorage.setItem(BLPG_LOGIN_STATUS, 0);
  }

  setMFAToken = (value) => {
    localStorage.setItem(BLPG_MFA_TOKEN, value);
  }
  getMFAToken = () => {
    return localStorage.getItem(BLPG_MFA_TOKEN);
  }

  setIPDevice = (value) => {
    return localStorage.setItem(KEY_DEVICE_IP, value)
  }
}

const instance = new LocalStorage();

export default instance;