import { Content, Footer, Header, Sidebar } from "@banklampung/components";
import PropTypes from "prop-types";
import React from "react";

const Layout = (props) => {
  const {
    propsSidebar,
    propsHeader,
    propsContent,
    propsFooter,
    routes,
  } = props;
  
  return (
    <div className="c-app c-default-layout">
      <Sidebar {...propsSidebar} />
      <div className="c-wrapper">
        <Header routes={routes} {...propsHeader} />
        <div className="c-body">
          <Content routes={routes} {...propsContent} />
        </div>
        <Footer {...propsFooter} />
      </div>
    </div>
  );
};

Layout.propTypes = {
  propsSidebar: PropTypes.object,
  propsHeader: PropTypes.object,
  propsContent: PropTypes.object,
  routes: PropTypes.array,
};

Layout.defaultProps = {
  propsSidebar: {},
  propsHeader: {},
  propsContent: {},
  propsFooter: {},
  routes: [],
};

export default Layout;
