import React from "react";
import { useDispatch } from "react-redux";
import { ActionBtnTable } from "@banklampung/components";
import * as GlobalActions from "@banklampung/store/actions";
import { useDeleteApi } from "@banklampung/hooks";

const CrudModalAction = props => {
  const {
    resources,
    resourceName,
    model,
    endpointApi,
    actionReloadTable,
    actionChangeForm
  } = props;

  const dispatch = useDispatch();

  const [isLoading, doDeleteRole] = useDeleteApi({
    onSuccess: ({ message }) => onSuccessDelete(message)
  });

  const handleClickShow = () => {
    dispatch({
      type: actionChangeForm,
      payload: {
        state: "SHOW",
        ...model
      }
    });
  };

  const handleEdit = () => {
    dispatch(
      GlobalActions.openModal({
        title: `Form Edit ${resourceName}`
      })
    );
    dispatch({
      type: actionChangeForm,
      payload: {
        state: "UPDATE",
        ...model
      }
    });
  };

  const handleDelete = () => {
    doDeleteRole({
      url: `${endpointApi}/${model.id}`
    });
  };

  const onSuccessDelete = message => {
    dispatch(
      GlobalActions.openDialog({
        icon: "success",
        text: message,
        showConfirmButton: true,
        onConfirm: () => {
          dispatch({
            type: actionReloadTable,
            payload: new Date().getTime()
          });

          dispatch(GlobalActions.closeDialog());
        }
      })
    );
  };

  return (
    <>
      <ActionBtnTable
        urlShow={resources && `${resources}/${model.id}`}
        textDelete={`Anda yakin menghapus ${resourceName}?`}
        onClickDelete={handleDelete}
        onClickEdit={handleEdit}
        onClickShow={handleClickShow}
      />
    </>
  );
};

export default CrudModalAction;
