import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";

const FooterSubmitForm = ({ button, align, className, children }) => {
  return (
    <div
      className={classNames(
        className,
        `d-flex justify-content-${align || "center"}`
      )}
    >
      {button || children}
    </div>
  );
};

FooterSubmitForm.propTypes = {
  button: PropTypes.any.isRequired,
};

export default FooterSubmitForm;
