import {
  FooterSubmitForm,
  FormItemRepeat,
  IconButton,
} from "@banklampung/components";
import { FormHelper } from "@banklampung/helper";
import _ from "@banklampung/libs/lodash";
import { FormValidator } from "@banklampung/utils";
import React, { memo, useCallback, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useList, useUnmount, useUpdateEffect } from "react-use";

const BasicFormRepeat = ({
  formId,
  formName,
  onSubmitPressed,
  blankAttribute,
  initialList,
  onChangeList,
  onFieldFormChange,
  update,
  action,
  showAddBtn,
  maxItem,
  canEmpty=false,
  startNumbering=1,
  visibility,
}) => {
  const propsItem = {
    style: {
      flex: `0 ${parseFloat(100 / maxItem).toFixed(2)}%`,
      padding: "0px 5px",
    },
  };

  const dispatch = useDispatch();
  const deff = useMemo(
    () =>
      initialList.map((val) => ({
        errors: {},
        invalids: {},
        errorMessages: {},
        hasError: true,
        hasValidated: false,
        ...val,
      })),
    [update]
  );

  const [list, { set, push, updateAt, removeAt }] = useList();

  const keyAttribute = blankAttribute.map((val) => val.id);

  const getValidateList = (values) => {
    return values.map((model, index) => {
      const result = FormHelper.bulkValidateById(
        keyAttribute.map((val) => `${index}-${val}`),
        `${formId}-${index}`
      );
      return {
        ...model,
        ...result,
        hasValidated: true,
      };
    });
  };

  const getValidateVals = (values) => {
    const hasError = values.filter(({ hasError }) => hasError).length > 0;
    const hasValidated =
      values.filter(({ hasValidated }) => hasValidated).length ===
      values.length;

    let addObj = {};
    if (hasValidated) {
      addObj = {
        validated: Date.now(),
      };
    }

    const payload = {
      ...addObj,
      list: values,
      values: values
        .map((value) => {
          return value.attribute.map((attr) => {
            let cval = attr.value;

            if (attr.fieldType === "checkbox") {
              cval = attr.checked;
            } else if (attr.type === "async-select") {
              cval = attr.onChangeSelect;
            }

            return {
              [attr.id]: cval,
            };
          });
        })
        .map((arr) =>
          _.reduce(
            arr,
            (memo, current) => {
              return _.assign(memo, current);
            },
            {}
          )
        ),
      hasError: hasError,
    };

    return payload;
  };

  const validateList = useMemo(
    () => getValidateList(list),
    [list, update, onSubmitPressed]
  );

  const validateVals = useMemo(() => getValidateVals(list), [list, update]);

  const validateListVals = useMemo(
    () => getValidateVals(getValidateList(list)),
    [list, update]
  );

  useEffect(() => {
    setTimeout(() => {
      set(deff);
    }, 50);
  }, [deff]);

  useEffect(() => {
    if (list.length !== 0 && canEmpty === false) {
      const payload = validateVals;
      if (onChangeList) {
        onChangeList(payload);
      }
    }

    if(canEmpty === true){
      const payload = validateVals;
      if (onChangeList) {
        onChangeList(payload);
      }
    }
  }, [list]);

  useUpdateEffect(() => {
    set(validateList);
  }, [onSubmitPressed]);

  useUnmount(() => {
    if (action) {
      dispatch({
        type: action,
        payload: validateListVals,
      });
    }
  });

  const handleAddItem = (event) => {
    const prevIndex = list.length - 1;
    const prevModel = list[prevIndex];

    const { errors, invalids, errorMessages, hasError } =
      FormHelper.bulkValidateById(
        keyAttribute.map((val) => `${prevIndex}-${val}`),
        `${formId}-${prevIndex}`
      );

    updateAt(prevIndex, {
      ...prevModel,
      errors,
      invalids,
      errorMessages,
      hasError: hasError,
    });

    push({
      attribute: blankAttribute,
      errors: {},
      invalids: {},
      errorMessages: {},
      hasError: true,
      hasValidated: false,
    });
  };

  const handleDelete = useCallback((event, position) => {
    event.preventDefault();

    removeAt(position);
  }, []);

  const handleChange = useCallback((event, model, position, posField) => {
    event.persist();

    const { input, value, result, hasError, errorMessage } =
      FormValidator.validateOnChange(event);
    const { errors, invalids, errorMessages, attribute } = model;
    const inputFieldType = attribute[posField]["fieldType"];

    if (inputFieldType === "checkbox") {
      attribute[posField]["checked"] = value;
    } else {
      attribute[posField]["value"] = value;
    }

    let newModel = model;
    if (onFieldFormChange) {
      newModel = onFieldFormChange(model, position, posField);
    }

    updateAt(position, {
      ...model,
      errors: {
        ...errors,
        [input.name]: result,
      },
      invalids: {
        ...invalids,
        [input.name]: hasError,
      },
      errorMessages: {
        ...errorMessages,
        [input.name]: errorMessage,
      },
      hasValidated: false,
    });
  }, []);

  return (
    <div>
      {list.map((model, position) => (
        <div key={position}>
          <FormItemRepeat
            propsItem={propsItem}
            formId={formId}
            formName={formName}
            model={model}
            position={position}
            onDelete={handleDelete}
            onFieldChange={handleChange}
            startIndex={startNumbering}
            deleteButtonPosition={-1}
            visibility={visibility}
          />
        </div>
      ))}
      {showAddBtn && (
        <FooterSubmitForm
          className="mt-3 mb-3"
          align="left"
          button={
            <IconButton
              color="primary"
              propsIcon={{ name: "cil-plus" }}
              label={`Tambah ${formName}`}
              onClick={handleAddItem}
            />
          }
        />
      )}
    </div>
  );
};

BasicFormRepeat.defaultProps = {
  formName: "Data",
  showAddBtn: true,
  maxItem: 2,
};
export default memo(BasicFormRepeat);
