class MaskingHelper {
  static toTaxpayerId = () => [
    /\d/,
    /\d/,
    ".",
    /\d/,
    /\d/,
    /\d/,
    ".",
    /\d/,
    /\d/,
    /\d/,
    ".",
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    ".",
    /\d/,
    /\d/,
    /\d/,
  ];

  static toTaxpayerId16 = () => [
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    " ",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    " ",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    " ",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ];

  static toAccountNumber = () => [
    /\d/,
    /\d/,
    /\d/,
    ".",
    /\d/,
    /\d/,
    ".",
    /\d/,
    /\d/,
    ".",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ".",
    /\d/,
  ];

  static toLedgerNumber = () => [
    /\d/,
    /\d/,
    ".",
    /\d/,
    /\d/,
    /\d/,
    ".",
    /\d/,
    /\d/,
    ".",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ];

  static toTaxObjectNumber = () => [
    /\d/,
    /\d/,
    ".",
    /\d/,
    /\d/,
    ".",
    /\d/,
    /\d/,
    /\d/,
    ".",
    /\d/,
    /\d/,
    /\d/,
    ".",
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ".",
    /\d/,
  ];

  static toNoticeTaxOfAssesmentNumber = () => [
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    "/",
    /\d/,
    /\d/,
    /\d/,
    "/",
    /\d/,
    /\d/,
    "/",
    /\d/,
    /\d/,
    /\d/,
    "/",
    /\d/,
    /\d/,
  ];

  static toIdCard = () => [
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ];

  static toCif = () => [
    /\d/,
    /\d/,
    /\d/,
    ".",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ];

  static toPhone = () => [
    "(",
    /\d/,
    /\d/,
    /\d/,
    ")",
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ];

  static toKeySoftToken = () => [/\w/, /\w/,/\w/, /\w/, "-", /\w/, /\w/,/\w/, /\w/, "-", /\w/, /\w/,/\w/, /\w/, "-", /\w/, /\w/,/\w/, /\w/]

  static toYear = () => [/\d/, /\d/, /\d/, /\d/];

  static to = (type) => {
    switch (type) {
      case "taxpayerId":
        return this.toTaxpayerId();

      case "taxpayer16":
        return this.toTaxpayerId16();

      case "accountNumber":
        return this.toAccountNumber();

      case "ledgerNumber":
        return this.toLedgerNumber();

      case "taxObjectNumber":
        return this.toTaxObjectNumber();

      case "noticeTaxOfAssesmentNumber":
        return this.toNoticeTaxOfAssesmentNumber();

      case "idCard":
        return this.toIdCard();

      case "cif":
        return this.toCif();

      case "phone":
        return this.toPhone();

      case "year":
        return this.toYear();

      case "softToken":
        return this.toKeySoftToken();

      default:
        return [];
    }
  };
}

export default MaskingHelper;