import PropTypes from "prop-types";
import React from "react";
import { CModalBody, CModalFooter, CForm } from "@coreui/react";
import { BasicModal } from "@banklampung/components";

const FormModal = ({ formName, body, footer, onSubmit }) => {
  return (
    <BasicModal
      body={
        <CForm onSubmit={onSubmit} name={formName}>
          <CModalBody>
            {body}
            <div className="required">* Mandatory</div>
          </CModalBody>
          <CModalFooter>{footer}</CModalFooter>
        </CForm>
      }
    />
  );
};

FormModal.propTypes = {
  body: PropTypes.any.isRequired,
  formName: PropTypes.string.isRequired,
  body: PropTypes.any.isRequired,
  footer: PropTypes.any.isRequired,
  onSubmit: PropTypes.any.isRequired
};

export default FormModal;
