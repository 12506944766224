import React from "react";
import MaskedInput from "react-text-mask";

const FieldMask = ({ param, validate, className, ...props }) => {
  return (
    <MaskedInput
      className={className}
      data-validate={validate}
      data-param={param}
      data-is-mask="1"
      {...props}
    />
  );
};

export default FieldMask;
