import hostInstance from "./hostInstance";

const callApi = ({ url = "", params = {}, headers = {} }) => {
  const promise = new Promise((resolve, reject) => {
    hostInstance
      .request({
        method: "get",
        url: url,
        params: params,
        headers: headers
      })
      .then(
        response => {
          resolve(response.data.data);
        },
        error => {
          reject(error);
        }
      );
  });

  return promise;
};

export default callApi;
