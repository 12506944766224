import { combineReducers } from "redux";
import auth from "./auth.reducer";
import dialog from "./dialog.reducer";
import modal from "./modal.reducer";
import sidebar from "./sidebar.reducer";

const createReducer = (asyncReducers) =>
  combineReducers({
    dialog,
    modal,
    auth,
    sidebar,
    ...asyncReducers,
  });

export default createReducer;
