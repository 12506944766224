import "react-app-polyfill/ie11"; // For IE 11 support
import "react-app-polyfill/stable";
import "./polyfill";
import React from "react";
import ReactDOM from "react-dom";
import App from "app/App";
import * as serviceWorker from "./serviceWorker";
import { icons } from "./assets/icons";

function checkBroswerVersion() {
    let isBrowserSupport;
    let typeBrowser;

    if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1) {
        isBrowserSupport = false;
        typeBrowser = "Opera";
    } else if (navigator.userAgent.indexOf("Edg") != -1) {
        isBrowserSupport = true;
        typeBrowser = "Edge";

    } else if (navigator.userAgent.indexOf("Chrome") != -1) {
        isBrowserSupport = true;
        typeBrowser = "Chrome";
        const raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);
        const version = raw ? parseInt(raw[2], 10) : false;
        typeBrowser = `Google Chrome versi ${version}`;
        if(parseInt(version) >= 50){
            isBrowserSupport = true;
        }else{
            isBrowserSupport = false;
        }

    } else if (navigator.userAgent.indexOf("Safari") != -1) {
        isBrowserSupport = false;
        typeBrowser = "Safari";
    } else if (navigator.userAgent.indexOf("Firefox") != -1) {
        const raw = window.navigator.userAgent.match(/Firefox\/([0-9]+)\./);
        const version = raw ? parseInt(raw[1]) : 0;
        typeBrowser = `Firefox versi ${version}`;

        if(parseInt(version) >= 62){
            isBrowserSupport = true;
        }else{
            isBrowserSupport = false;
        }
        
    } else if ((navigator.userAgent.indexOf("MSIE") != -1) || (!!document.documentMode == true)) //IF IE > 10
    {
        isBrowserSupport = false;
        typeBrowser = "Internet Explore";
    } else {
        isBrowserSupport = false;
        typeBrowser = false;
    }

    if(isBrowserSupport == false){
        const text = `Anda Menggunakan Browser ${typeBrowser}. Browser anda tidak didukung saat menggunakan aplikasi kami, silahkan coba menggunakan browser lain.`;
        alert(text);
        document.getElementById("text").innerHTML = text ;
        document.getElementById("center-screen").style.display = 'flex';
        document.getElementById("loader").style.display = 'none';
    }else{

        React.icons = icons;
        ReactDOM.render(<App />, document.getElementById("root"));
    }
}

window.onload = checkBroswerVersion();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
