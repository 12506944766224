import { BasicFormClean, Card, HeaderListClean, LoadLazy } from "@banklampung/components";
import React from "react";
import { faMagnifyingGlass, faRotateLeft } from "@fortawesome/free-solid-svg-icons";

const BasicFilterTop = ({
  pageTitle="",
  filterTitle="",
  contentTitle="",
  showButtonReset=true,
  hideContent=false,
  disableFilter=false,
  buttonSubmitLabel="Cari",
  buttonResetLabel="Atur Ulang Filter",
  slotContent,
  slotFilter,
  resourceLink,
  resourceModal,
  handleSubmit,
  handleReset,
  isLoading,
  createPermission,
  onClickBtn
}) => {

  return (
    <>
      <HeaderListClean
        createPermission={createPermission}
        historyLink={resourceLink}
        resourceName={pageTitle}
        toggleModal={resourceModal}
        onClick={onClickBtn}          
      />
      <hr />

      { disableFilter === false && (
        <BasicFormClean
          onReset={handleReset}
          onSubmit={handleSubmit}
          formName="formFilter"
          formLabel={`${filterTitle}`}
          formIcon="filter"
          body={slotFilter}
          buttonSubmit={{
            propsIcon: { icon: faMagnifyingGlass },
            label: buttonSubmitLabel,
            isLoading: isLoading,
            style:{ width: "30%" }
          }}
          buttonReset={ showButtonReset == true && {
            color: "secondary",
            propsIcon: { icon: faRotateLeft },
            label: buttonResetLabel,
            isLoading: isLoading,
            style:{ width: "30%" }
          }}
          buttonAlign="center"
        />
      )}

      <div hidden={hideContent}>
        <Card>
          <h5 className="mb-2 mt-3">{ contentTitle }</h5>

          <LoadLazy>{slotContent}</LoadLazy>
        </Card>
      </div>
    </>
  );
};

BasicFilterTop.propTypes = {};
export default BasicFilterTop;
