export const OPEN_MODAL = "[MODAL] OPEN";
export const CLOSE_MODAL = "[MODAL] CLOSE";

export function closeModal() {
  return {
    type: CLOSE_MODAL
  };
}

export function openModal(options) {
  return {
    type: OPEN_MODAL,
    options
  };
}
