import { BasicForm, BasicList, BasicHorForm } from "@banklampung/components";
import { CCol, CContainer, CRow } from "@coreui/react";
import React from "react";

const BasicHorFilter = ({
  name,
  slotContent,
  slotFilter,
  resourceName,
  resourceLink,
  resourceModal,
  handleSubmit,
  isLoading,
  createPermission,
  onClickBtn,
  colA = 9,
  colB = 3
}) => {
  return (
    <CContainer>
      <CRow>
        <CCol>
          <BasicHorForm
            onSubmit={handleSubmit}
            formName="formFilter"
            formLabel={`Filter ${resourceName}`}
            formIcon="filter"
            body={slotFilter}
            buttonSubmit={{
              propsIcon: { name: "cil-filter" },
              label: "Filter",
              isLoading: isLoading,
            }}
            buttonAlign="center"
          />
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <BasicList
            resourceName={resourceName}
            createPermission={createPermission}
            historyLink={resourceLink}
            resourceModal={resourceModal}
            onClickBtn={onClickBtn}
            body={slotContent}
          />
        </CCol>
      </CRow>
    </CContainer>
    // <ColTwo
    //   propsRow={{
    //     className: "flex-column-reverse flex-md-row",
    //   }}
    //   propsColA={{ md: colA }}
    //   propsColB={{ md: colB }}
    //   childA={
    // <BasicList
    //   resourceName={resourceName}
    //   createPermission={createPermission}
    //   historyLink={resourceLink}
    //   resourceModal={resourceModal}
    //   onClickBtn={onClickBtn}
    //   body={slotContent}
    // />
    //   }
    //   childB={
    //     <BasicForm
    //       onSubmit={handleSubmit}
    //       formName="formFilter"
    //       formLabel={`Filter ${resourceName}`}
    //       formIcon="filter"
    //       body={slotFilter}
    //       buttonSubmit={{
    //         propsIcon: { name: "cil-filter" },
    //         label: "Filter",
    //         isLoading: isLoading,
    //       }}
    //       buttonAlign="center"
    //     />
    //   }
    // />
  );
};

BasicHorFilter.propTypes = {};

export default BasicHorFilter;
