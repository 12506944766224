import React from "react";
import PropTypes from "prop-types";
import { CAlert } from "@coreui/react";

const Alert = ({ color, title, content, propsWrapper }) => {
  return (
    <CAlert color={color} {...propsWrapper}>
      <h4 className="alert-heading">{title}</h4>
      <p>{content}</p>
    </CAlert>
  );
};

Alert.propTypes = {
  color: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
};

Alert.defaultProps = {
  propsWrapper: {},
};

export default Alert;
