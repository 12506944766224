import { SpinChaseDot } from "@banklampung/components";
import React from "react";

const SpinChase = (props) => {
  return (
    <span className="sk-chase">
      <SpinChaseDot />
      <SpinChaseDot />
      <SpinChaseDot />
      <SpinChaseDot />
      <SpinChaseDot />
      <SpinChaseDot />
    </span>
  );
};

export default SpinChase;
