import * as Actions from "../actions";

const initialState = {
  state: false,
  options: {
    title: "",
    backdrop: "static",
    size: "lg"
  }
};

const modal = function(state = initialState, action) {
  switch (action.type) {
    case Actions.OPEN_MODAL: {
      return {
        ...state,
        state: true,
        options: {
          ...initialState.options,
          ...action.options
        }
      };
    }
    case Actions.CLOSE_MODAL: {
      return {
        ...state,
        state: false
      };
    }
    default: {
      return state;
    }
  }
};

export default modal;
