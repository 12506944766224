import { BasicForm, BasicList, ColTwo } from "@banklampung/components";
import { notEmpty } from "@banklampung/helper";
import React from "react";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

const BasicFilter = ({
  name,
  slotContent,
  slotFilter,
  resourceName,
  resourceLink,
  resourceModal,
  handleSubmit,
  isLoading,
  createPermission,
  onClickBtn,
  colA = 9,
  colB = 3,
  type = "DEFAULT",
  buttonSubmitLabel="Filter",
  ...props
}) => {
  return (
    <ColTwo
      propsRow={{
        className: "flex-column-reverse flex-md-row",
      }}
      propsColA={{ md: colA }}
      propsColB={{ md: colB }}
      childA={
        <>
          {(notEmpty(slotFilter) && type == 'TOP') && (
            <BasicForm
              onSubmit={handleSubmit}
              formName="formFilter"
              formLabel={`Filter ${resourceName}`}
              formIcon="filter"
              body={slotFilter}
              buttonSubmit={{
                propsIcon: { icon: faMagnifyingGlass },
                label: buttonSubmitLabel,
                isLoading: isLoading,
                style:{ width: "40%" }
              }}
              buttonAlign="around"
            />
          )}

          <BasicList
            resourceName={resourceName}
            createPermission={createPermission}
            historyLink={resourceLink}
            resourceModal={resourceModal}
            onClickBtn={onClickBtn}
            body={slotContent}
            {...props}
          />
        </>
      }
      childB={
        (notEmpty(slotFilter) && type == 'DEFAULT') && (
          <BasicForm
            onSubmit={handleSubmit}
            formName="formFilter"
            formLabel={`Filter ${resourceName}`}
            formIcon="filter"
            body={slotFilter}
            buttonSubmit={{
              propsIcon: { name: "cil-filter" },
              label: "Filter",
              isLoading: isLoading,
            }}
            buttonAlign="center"
          />
        )
      }
    />
  );
};

BasicFilter.propTypes = {};

export default BasicFilter;
