import React, { lazy } from "react";
import PropTypes from "prop-types";
import { LoadLazy, Card } from "@banklampung/components";

const StepZilla = lazy(() => import("@banklampung/libs/stepzilla"));

const BasicWizard = (props) => {
  const {
    title,
    subTitle,
    steps,
    showNextBtn,
    showPrevBtn,
    propsCard,
    propsStep,
    showNavigation,
    nextButtonText,
    backButtonText,
    stepsNavigation,
    preventEnterSubmission,
    startAtStep,
    slotBodyTop,
  } = props;

  return (
    <Card
      headerSlot={
        <>
          <strong>{title}</strong> {subTitle && <small>{subTitle}</small>}
        </>
      }
      {...propsCard}
    >
      <LoadLazy>{slotBodyTop}</LoadLazy>
      <LoadLazy>
        <StepZilla
          propsStep={propsStep}
          steps={steps}
          showNavigation={showNavigation}
          nextButtonText={nextButtonText}
          backButtonText={backButtonText}
          nextButtonCls={`btn btn-md btn-primary pull-right${
            showNextBtn ? "" : " d-none"
          }`}
          backButtonCls={`btn btn-md btn-primary pull-left${
            showPrevBtn ? "" : " d-none"
          }`}
          stepsNavigation={stepsNavigation}
          preventEnterSubmission={preventEnterSubmission}
          startAtStep={startAtStep}
        />
      </LoadLazy>
    </Card>
  );
};

BasicWizard.propTypes = {
  title: PropTypes.string.isRequired,
  steps: PropTypes.any.isRequired,
  showNextBtn: PropTypes.bool.isRequired,
};

BasicWizard.defaultProps = {
  propsCard: {},
  showNextBtn: true,
  showNavigation: true,
  nextButtonText: "Lanjutkan",
  backButtonText: "Kembali",
  stepsNavigation: false,
  preventEnterSubmission: true,
  startAtStep: 0,
};

export default BasicWizard;
